import React, { FC } from 'react';
import { BrandHeader } from './brandheader';
import { Nav } from './nav';



export const Header: FC = () => {
  return (
    <>
          <BrandHeader />
             <Nav />

    </>
  );
};
