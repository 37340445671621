import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import MapIcon from "@material-ui/icons/Map";
import { useLocation } from "react-router-dom";

type SpeedDialProps = {
  className?: string;
  dashboard?: boolean;
  partDetail?: boolean;
  planningDetail?: boolean;
};

export const SpeedDialComponent: FC<SpeedDialProps> = ({
  className,
  dashboard,
  partDetail,
  planningDetail,
}) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseTour = () => {
    setOpen(false);
    localStorage.clear();
  };

  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <Box className={classes.root}>
      <SpeedDial
        className={className}
        ariaLabel="SpeedDial-selection"
        icon={
          <div
            className={classes.helpMenuContainer}
            data-tut={"reactour__help-Menu"}
          >
            <Typography
              component={"h2"}
              variant={"h4"}
              style={{ color: "white" }}
            >
              ?
            </Typography>
          </div>
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {/* <SpeedDialAction
          key={"leave-feedback"}
          icon={
            <Link
              aria-label={"leave-feedback"}
              rel="noreferrer"
              target="_blank"
              href="#"
            >
              <MessageIcon />
            </Link>
          }
          tooltipTitle={"Leave Feedback"}
          tooltipOpen
          onClick={handleClose}
        /> */}

        <SpeedDialAction
          key={"contact-support"}
          icon={
            <Link
              role={"link"}
              aria-label="Contact support link"
              onClick={handleDialogOpen}
            >
              <ContactSupportIcon />
            </Link>
          }
          tooltipTitle={"Contact Support"}
          tooltipOpen
          onClick={handleClose}
        />

        <SpeedDialAction
          key={"quick-reference-guide"}
          icon={
            <Link
              aria-label={"download-quick-reference-guide"}
              href={window.location.origin.match("dev")
              ? "https://deventassetstoolsto.blob.core.windows.net/toolsdisposal-webapp-static/tooling-disposal-app.pdf"
              : window.location.origin.match("stg")
              ? "https://stgentassetstoolsto.blob.core.windows.net/toolsdisposal-webapp-static/tooling-disposal-app.pdf"
              : "https://prdentassetstoolsto.blob.core.windows.net/toolsdisposal-webapp-static/tooling-disposal-app.pdf"}
              download
            >
              <PictureAsPdfIcon />
            </Link>
          }
          tooltipTitle={"Download Quick Reference Guide"}
          tooltipOpen
          onClick={handleClose}
        />
        <SpeedDialAction
          key={"quick-reference-guide"}
          icon={
            <Link
              aria-label={"download-quick-reference-guide"}
              href={location.pathname}
              // download
            >
              <DynamicFeedIcon />
            </Link>
          }
          tooltipTitle={"Guided Tour"}
          tooltipOpen
          onClick={handleCloseTour}
        />

        {dashboard && (
          <SpeedDialAction
            key={"take-guided-tour"}
            tooltipTitle={"Take Guided Tour"}
            FabProps={{ "aria-label": "take guided tour" }}
            onClick={() => {}}
            icon={<MapIcon />}
            tooltipOpen
          />
        )}

        {partDetail && (
          <SpeedDialAction
            key={"take-guided-tour"}
            tooltipTitle={"Take Guided Tour"}
            FabProps={{ "aria-label": "take guided tour" }}
            onClick={() => {}}
            icon={<MapIcon />}
            tooltipOpen
          />
        )}

        {planningDetail && (
          <SpeedDialAction
            key={"take-guided-tour"}
            tooltipTitle={"Take Guided Tour"}
            FabProps={{ "aria-label": "take guided tour" }}
            onClick={() => {}}
            icon={<MapIcon />}
            tooltipOpen
          />
        )}
      </SpeedDial>

      <Dialog
        onClose={handleDialogClose}
        aria-labelledby={"Contact Support"}
        open={dialogOpen}
      >
        <DialogTitle>{"Contact Support"}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogBody}>
            For technical support, contact : purchasing.systems@Polaris.com
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpMenuContainer: {
      width: "4.6875rem",
      height: "3.125rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    root: {
      position: "absolute",
      "& .MuiFab-root": {
        lineHeight: "initial",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "& .MuiSpeedDialAction-fab": {
        background: theme.palette.primary.main,
      },
      "& .MuiSpeedDialAction-fab:hover": {
        background: theme.palette.primary.dark,
      },
      "& .MuiSpeedDialAction-staticTooltipLabel": {
        color: theme.palette.text.primary,
        padding: theme.spacing(0, 1),
        fontSize: "0.875rem",
        width: "max-content",
      },
    },
    quickRefGuide: {
      //fontWeight: theme.typography.fontWeightBold,
    },
    dialogBody: {
      color: theme.palette.text.primary,
      padding: theme.spacing(1, 0),
    },
  })
);
