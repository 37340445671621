import { Table } from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

export const ToolDisposalTableComponent = ({ ...restProps }: any) => {
  const classes = useStyles();
  return <Table.Table {...restProps} className={classes.tableStriped} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableStriped: {
      '& tbody tr:nth-of-type(even)': {
        backgroundColor: theme.palette.primary.light,
      },
      '& .MuiTableHead-root .MuiButton-root': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'left',
        padding: 0,
      },
      [`@media print`]: {
        '& .MuiTableHead-root .MuiButton-root': {
          color: theme.palette.text.primary,
          textAlign: 'left',
        },
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
      '& thead': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);
