import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PaginatableLoadable } from "../services/shared/baseTypes";
import {
  productLineRequest,
  toolTypeRequest,
  stateRequest,
  projectNumberRequest,
  supplierRequest,
  IApproval,
  IAttachments,
  countryRequest,
  toolDisposalRequest,
  IToolDisposalRequest,
  toolRequest,
  ItoolDisposalFilterset,
  ToolDisposalSortOption,
  ListSortDirection,
} from "../services/generated/ApiClientGenerated";

export type ToolState = {
  availableFilters: any;
  updateCreateToolResponse: IToolDisposalRequest;
  toolDisposals: PaginatableLoadable<any>;
  filters: ItoolDisposalFilterset;
  pageSizes: number[];
  toolInformation: any;
  updateValues: any;
  requestorInfo: any;
  approvers: any;
  isDashboardTourOpen: boolean;
  isNewRequestTourOpen: boolean;
  isSaveSubmitLoader: boolean;
  attachmentsResponse: object;
  approveRejectResponse: string;
};

export type Sorting = {
  direction: string;
  columnName: string;
};

export type UpdateToolDisposalType = {
  body: IToolDisposalRequest;
};

export type updateCreateToolResponseType = {
  value: IToolDisposalRequest;
};

export const ToolDisposalSlice = createSlice({
  name: "toolDisposalRequests",
  initialState: {
    availableFilters: {},
    isDashboardTourOpen: false,
    isNewRequestTourOpen: false,
    isSaveSubmitLoader: false,
    toolDisposals: {
      loading: false,
      loaded: false,
      value: [],
      pageSize: 10,
    },
    updateCreateToolResponse: {},
    approveRejectResponse: "",
    pageSizes: [5, 10, 15, 50, 100],

    updateValues: {
      value: [],
    },
    attachmentsResponse: {
      loading: false,
      loaded: false,
      originalAttachmentValue: "" as string,
      moveAttachmentValue: "empty" as string,
      deleteAttachmentValue: "" as string,
      downloadAttachmentValue: "" as string,
    },
    requestorInfo: {
      requestorValue: [],
    },
    approvers: {
      approversValue: [],
    },
    toolInformation: {
      productLinesValue: [],
      toolTypesValue: [],
      countriesValue: [],
      projectNumberValue: [],
      suppliersValue: [],
      suppliersNumberValue: [],
      statesValue: [],
      itemValue: [],
      itemForSupplierValue: [],
      toolValue: [],
      idValue: [],
    },

    filters: {
      SortDirection: ListSortDirection.Descending,
      SortColumn: ToolDisposalSortOption.lastModifiedDate,
      PageSize: "",
      PageNumber: 1,
      AssignedTo: "",
      CompletedDateMax: "",
      CompletedDateMin: "",
      LastModifiedDateMax: "",
      LastModifiedDateMin: "",
      RequestedBy: "",
      SearchTerm: "",
      DisposalStatusId: "",
      RequestId: "",
      CurrentUserEmail: "",
    } as ItoolDisposalFilterset,
  } as ToolState,
  reducers: {
    getAvailableFiltersAction: (state) => {},

    getToolDisposalAction: (state) => {
      state.toolInformation.loading = true;
      state.toolInformation.loaded = false;
    },

    isDashboardTourOpen: (state, action: PayloadAction<boolean>) => {
      state.isDashboardTourOpen = action.payload;
    },
    isNewRequestTourOpen: (state, action: PayloadAction<boolean>) => {
      state.isNewRequestTourOpen = action.payload;
    },
    isSaveSubmitLoader: (state, action: PayloadAction<boolean>) => {
      state.isSaveSubmitLoader = action.payload;
    },

    getNewRequest_productLinesAction: (state) => {
      state.toolInformation.loading = true;
      state.toolInformation.loaded = false;
    },
    getNewRequest_toolTypesAction: (state) => {},

    submitDataAction: (state, action: PayloadAction<any>) => {
      state.updateValues = {
        ...state.updateValues,
        value: action.payload.body,
      };
    },
    updateDisposalRequestAction: (
      state,
      action: PayloadAction<UpdateToolDisposalType>
    ) => {},
    approve_put_Action: (state, action: PayloadAction<IApproval>) => {},
    requestorAction: (state) => {},
    getApproversAction: (state, action) => {},
    getNewRequest_toolAction: (state, action) => {},
    getNewRequest_suppliersAction: (state, action) => {
      state.toolInformation = {
        ...state.toolInformation,
        itemValue: [],
      };
    },
    postAttachmentToRequestIdAction: (
      state,
      action: PayloadAction<{
        file: IAttachments;
        originalRequestId: IAttachments;
      }>
    ) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        loading: true,
        loaded: false,
      };
    },
    postAttachmentToTempFolderAction: (
      state,
      action: PayloadAction<{
        file: IAttachments;
        tempRequestId: IAttachments;
      }>
    ) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        loading: true,
        loaded: false,
      };
    },

    postAttachmentToMoveFileAction: (
      state,
      action: PayloadAction<IAttachments>
    ) => {},
    postAttachmentDeleteFileAction: (
      state,
      action: PayloadAction<IAttachments>
    ) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        deleteAttachmentValue: "",
      };
    },

    gettAttachmentDownloadFileAction: (
      state,
      action: PayloadAction<IAttachments>
    ) => {},

    setToolDisposalPageAction: (state, action: PayloadAction<number>) => {
      state.toolDisposals.loading = true;
      state.toolDisposals.loaded = false;
      state.filters.PageNumber = action.payload;
    },

    setToolDisposalPageSizeAction: (state, action: PayloadAction<number>) => {
      state.toolDisposals.loading = true;
      state.toolDisposals.loaded = false;
      state.filters.PageSize = action.payload;
    },

    setToolDisposalFiltersAction: (
      state,
      action: PayloadAction<{
        key: keyof ItoolDisposalFilterset;
        value: ItoolDisposalFilterset[keyof ItoolDisposalFilterset];
      }>
    ) => {
      (state.filters as any)[action.payload.key] = action.payload.value;
    },

    setToolDisposalSortAction: (state, action: PayloadAction<Sorting[]>) => {
      if (action.payload.length === 0) {
        state.filters.SortColumn = ToolDisposalSortOption.Undefined;
        state.filters.SortDirection = ListSortDirection.Descending;
      } else {
        const sortOption = action.payload[0].columnName;
        state.filters.SortColumn = sortOption.replace(/^./, (m) =>
          m.toUpperCase()
        ) as ToolDisposalSortOption;
        state.filters.SortDirection =
          action.payload[0].direction === "asc"
            ? ListSortDirection.Ascending
            : ListSortDirection.Descending;
      }
    },

    clearToolDisposalFiltersAction: (state) => {
      let requestor = (state.requestorInfo = {
        ...state.requestorInfo,
      });
      state.filters = {
        ...state.filters,
        SortDirection: ListSortDirection.Descending,
        SortColumn: ToolDisposalSortOption.lastModifiedDate,
        PageNumber: 1,
        AssignedTo: "",
        CompletedDateMax: "",
        CompletedDateMin: "",
        LastModifiedDateMax: "",
        LastModifiedDateMin: "",
        RequestedBy: requestor.requestorValue.mail,
        CurrentUserEmail: requestor.requestorValue.mail,
        SearchTerm: "",
        DisposalStatusId: "",
        RequestId: "",
      };
    },

    succesRequestorAction: (state, action) => {
      state.requestorInfo = {
        ...state.requestorInfo,
        requestorValue: action.payload,
      };
    },
    succesAvailableFiltersAction: (state, action) => {
      state.availableFilters = {
        ...state.availableFilters,
        value: action.payload,
      };
    },

    succespostAttachmentAction: (state, action) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        loading: false,
        loaded: true,
        originalAttachmentValue: action.payload,
      };
    },
    succespostAttachmentMoveFileAction: (state, action) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        moveAttachmentValue: action.payload,
      };
    },
    succespostAttachmentDeleteFileAction: (state, action) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        deleteAttachmentValue: action.payload,
      };
    },
    succespostAttachmentDownloadFileAction: (state, action) => {
      state.attachmentsResponse = {
        ...state.attachmentsResponse,
        downloadAttachmentValue: action.payload,
      };
    },
    succesupdateAndCreateDisposalAction: (
      state,
      action: PayloadAction<toolDisposalRequest[]>
    ) => {
      let payLoadData = action.payload[0];
      state.updateCreateToolResponse = {
        ...state.updateCreateToolResponse,
        ...payLoadData,
      };
    },

    succesApproveRejectResponseAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.approveRejectResponse = action.payload;
    },
    succesApproversAction: (state, action) => {
      state.approvers = {
        ...state.approvers,
        approversValue: action.payload,
      };
    },

    successToolDisposalAction: (
      state,
      action: PayloadAction<toolDisposalRequest[]>
    ) => {
      state.updateValues = {
        ...state.updateValues,
        value: [],
      };
      state.updateCreateToolResponse = {};
      state.toolDisposals = {
        ...state.toolDisposals,
        value: action.payload,
      };
    },

    successRequestIdUpdateAction: (
      state,
      action: PayloadAction<toolDisposalRequest[]>
    ) => {
      state.filters = {
        ...state.filters,
        RequestId: "",
      };
      state.toolDisposals = {
        ...state.toolDisposals,
        value: [],
      };
      state.updateValues = {
        ...state.updateValues,
        value: action.payload[0],
      };
    },
    successNewRequest_productLinesAction: (
      state,
      action: PayloadAction<productLineRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        productLinesValue: action.payload,
      };
    },

    successNewRequest_toolTypesAction: (
      state,
      action: PayloadAction<toolTypeRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        toolTypesValue: action.payload,
      };
    },

    successNewRequest_toolAction: (
      state,
      action: PayloadAction<toolRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        toolValue: action.payload,
      };
    },
    successNewRequest_countriesAction: (
      state,
      action: PayloadAction<countryRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        countriesValue: action.payload,
      };
    },
    successNewRequest_projectNumberAction: (
      state,
      action: PayloadAction<projectNumberRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        projectNumberValue: action.payload,
      };
    },
    successNewRequest_suppliersAction: (
      state,
      action: PayloadAction<supplierRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        suppliersValue: action.payload,
      };
    },
    successNewRequest_suppliersNumberAction: (
      state,
      action: PayloadAction<supplierRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        suppliersNumberValue: action.payload,
      };
    },

    successNewRequest_statesAction: (
      state,
      action: PayloadAction<stateRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        statesValue: action.payload,
      };
    },
    successNewRequest_itemForSupplierAction: (
      state,
      action: PayloadAction<stateRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        itemForSupplierValue: action.payload,
      };
    },

    successNewRequest_itemAction: (
      state,
      action: PayloadAction<stateRequest[]>
    ) => {
      state.toolInformation = {
        ...state.toolInformation,
        itemValue: action.payload,
      };
    },
    id_stateAction: (state, action: PayloadAction<any[]>) => {
      state.toolInformation = {
        ...state.toolInformation,
        idValue: action.payload,
      };
    },
    getNewRequest_stateAction: (
      state,
      action: PayloadAction<stateRequest[]>
    ) => {},
    getNewRequest_itemsAction: (state, action: PayloadAction<any[]>) => {},
  },
});

export const {
  getAvailableFiltersAction,
  getToolDisposalAction,
  getNewRequest_productLinesAction,
  getNewRequest_toolTypesAction,
  getNewRequest_stateAction,
  getNewRequest_itemsAction,
  submitDataAction,
  updateDisposalRequestAction,
  postAttachmentToRequestIdAction,
  postAttachmentToTempFolderAction,
  postAttachmentToMoveFileAction,
  postAttachmentDeleteFileAction,
  approve_put_Action,
  requestorAction,
  getApproversAction,
  getNewRequest_toolAction,
  gettAttachmentDownloadFileAction,
  setToolDisposalPageAction,
  setToolDisposalPageSizeAction,
  setToolDisposalFiltersAction,
  setToolDisposalSortAction,
  clearToolDisposalFiltersAction,
  succesApproversAction,
  succesRequestorAction,
  successNewRequest_toolAction,
  successNewRequest_productLinesAction,
  successNewRequest_toolTypesAction,
  successNewRequest_countriesAction,
  successNewRequest_projectNumberAction,
  successNewRequest_suppliersAction,
  successNewRequest_suppliersNumberAction,
  succespostAttachmentDownloadFileAction,
  successNewRequest_itemForSupplierAction,
  succespostAttachmentAction,
  getNewRequest_suppliersAction,
  successNewRequest_statesAction,
  successNewRequest_itemAction,
  successToolDisposalAction,
  succesAvailableFiltersAction,
  successRequestIdUpdateAction,
  succesupdateAndCreateDisposalAction,
  succespostAttachmentMoveFileAction,
  succespostAttachmentDeleteFileAction,
  succesApproveRejectResponseAction,
  id_stateAction,
  isDashboardTourOpen,
  isNewRequestTourOpen,
  isSaveSubmitLoader,
} = ToolDisposalSlice.actions;
