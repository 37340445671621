import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import Tour, { ReactourStep } from "reactour";
import { isNewRequestTourOpen } from "../toolDisposalSlice";
import { useAppData } from "../../services/hooks";

export const DashboardWalkthrough: FC = () => {
  const dispatch = useDispatch();
  const isTourOpen = useAppData(
    (r) => r.toolDisposalSlice.isNewRequestTourOpen
  );
  const bodyScrollLock = require("body-scroll-lock");
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const closeTour = () => {
    dispatch(isNewRequestTourOpen(false));
  };

  const steps: ReactourStep[] = [
    {
      selector: "[data-tut=reactour__Submit]",
      content: "Click Submit to route request for approval.",
    },
    {
      selector: "[data-tut=reactour__save]",
      content: "Click Save to save request as a draft and return later",
    },
    {
      selector: "[data-tut=reactour__printerIcon]",
      content:
        "To create a printable PDF of your request form, click the printer icon.",
    },
    {
      selector: "[data-tut=reactour__attach]",
      content: "Click the Attach icon to attach a relavent file",
    },
    {
      selector: "[data-tut=reactour__requestInformation]",
      content: "Explain what is prompting the disposition request",
    },
    {
      selector: "[data-tut=reactour__approvers]",
      content:
        "Select the relavant approver for each group, users are not required to approve in sequential order.",
    },
    {
      selector: "[data-tut=reactour__toolInformation]",
      content:
        "Include additional relavent information (affected project numbers/platforms, refurb history, are multiple tools included in this request?)",
    },
    {
      selector: "[data-tut=reactour__addPart]",
      content:
        "Enter the part number(s) produced by this tool (include all serviceable kits and assemblies as well)",
    },
    {
      selector: "[data-tut=reactour__addNote]",
      content: "Add additional comments if needed.",
    },
    {
      selector: "[data-tut=reactour__dispositionInformation]",
      content:
        "Indicate reason for disposition request and actions taken to eliminate risk (supercession, obsoletion, last time purchase).",
    },
  ];

  return (
    <Tour
      badgeContent={(curr: number, tot: number) => `${curr} of ${tot}`}
      steps={steps}
      rounded={5}
      isOpen={isTourOpen}
      onRequestClose={closeTour}
      accentColor={"#004e97"}
      prevButton={<ArrowBackIcon aria-label={"go to previous step"} />}
      nextButton={<ArrowForwardIcon aria-label={"go to next step"} />}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
    />
  );
};
