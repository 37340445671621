import { connectRouter } from "connected-react-router";
import { History, LocationState } from "history";
import { combineReducers } from "redux";
import { ToolDisposalSlice } from "./toolDisposalRequests/toolDisposalSlice";
import { globalSlice } from "./features/globalSlice";

export function createRootReducer<L = LocationState>(history: History<L>) {
  return combineReducers({
    router: connectRouter(history),
    global: globalSlice.reducer,
    toolDisposalSlice: ToolDisposalSlice.reducer,
  });
}
