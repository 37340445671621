import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { FC } from 'react';
import { useHistory } from 'react-router';

export const NotFoundPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container className={classes.grid} justifyContent="space-around" alignItems="center">
      <Grid item className={classes.wrapper}>
        <Typography className={classes.title} component="h1">
          404
        </Typography>
        <Typography className={classes.subtitle} component="h2">
          Page Not Found
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          It looks like you are trying to access a page that either has been deleted or never existed
        </Typography>
        <Button variant="outlined" color={'primary'} onClick={() => history.goBack()}>
          Take Me Back
        </Button>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      textAlign: 'center',
    },
    wrapper: {
      maxWidth: '26rem',
    },
    title: {
      fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      fontSize: '5.375rem',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    subtitle: {
      fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      fontSize: '3.125rem',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  }),
);
