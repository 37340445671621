import {
  EditingState,
  PagingState,
  RowDetailState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  Table,
  TableColumnVisibility,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Paper, Box, IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import { ToolDisposalCellComponent } from './newRequestCellComponent';
import { ToolDisposalNoDataCell } from './newRequestNoDataCell';
import { ToolDisposalSortLabel } from './newRequestSortLabel';
import { ToolDisposalTableComponent } from './newRequestTableComponent';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AddPartModel } from './newRequestAddPartModel';
import { useAppData } from '../../services/hooks';
import { useDispatch } from "react-redux";
import {
  submitDataAction,
} from '../toolDisposalSlice';

export type ColumnType = {
  name: string;
  title: string;
  wordWrapEnabled?: boolean;
  renderCell?: any
};

export type TableColumnExtensions = {
  columnName: string;
  wordWrapEnabled: boolean;
  width?: number | string;
};

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};



export const NewRequestGrid: FC<any> = ({ SupplierData }) => {
  const classes = useStyles();
  const [partNumber, setPartNumber] = React.useState([""]);
  const row = useAppData((r) => r.toolDisposalSlice.updateValues.value);
  const [disabled, setdisabled] = React.useState(true);
  const dispatch = useDispatch();
  const itemValue: any = useAppData((r) => r.toolDisposalSlice.toolInformation.itemValue);
  let gridRow = row && row.partDetails ? row.partDetails.partDetail : []
  var gridRowUpdated = gridRow.map((data: any) => ({
    ...data,
    icon: <IconButton
      color={  row && (row.disposalStatusId === "2" || row.disposalStatusId === "3" || row.disposalStatusId === "4") ? 'secondary' : 'primary'}
      onClick={() => row && (row.disposalStatusId === "2" || row.disposalStatusId === "3" || row.disposalStatusId === "4") ? console.log("") : deletePartListing(data)}
    >
      <DeleteForeverIcon />
    </IconButton>
  }));
  const deletePartListing = (e) => {
    let data = gridRow.filter(i => !i.partNumber.includes(e.partNumber));
    handlePartDetails(data)
  }
  const totalPartData = itemValue.map((items) => {
    return {
      partNumber: items.partNumber,
      partDesc: items.partDescription,
      engDrawingNo: items.engDrawingNumber,
    };
  });
  const createTagHandler = (tagType: 'partDetails') => (value: any) => {

    const { ...rowdata } = row

    dispatch(
      submitDataAction({
        body: {
          ...rowdata,
          [tagType]: { partDetail: value },
        },
      }),
    );

  };

  const handlePartDetails = createTagHandler('partDetails');

  const merge = (...arrays) => {
    const merged = {};

    arrays.forEach((data) =>
      data.forEach((o) => Object.assign((merged[o.partNumber] ??= {}), o))
    );

    return Object.values(merged);
  };


  const onConfirmClick = () => {

    const filtered_item = totalPartData.filter((item) => partNumber.includes(item.partNumber));
     const newFilter_item = merge(gridRow, filtered_item)
    handlePartDetails(newFilter_item)
  };


  function handlePartNumberChange<TKey extends keyof any>(key: TKey, value: any[TKey]) {
    let data = value.map(item => item.partNumber)
    if (partNumber && partNumber.length > 0) {
      setdisabled(false)
    } else { setdisabled(true) }
    setPartNumber(data)
  }


  const columns: ColumnType[] = [
    { name: 'id', title: 'ID' },
    { name: 'partNumber', title: 'Part Number ', wordWrapEnabled: true },
    { name: 'partDesc', title: 'part description', wordWrapEnabled: true },
    { name: 'engDrawingNo', title: 'Engineering drawing number', wordWrapEnabled: true },
    { name: "icon", title: "Remove" },


  ];
  const tableColumnExtensions: TableColumnExtensions[] = [

    {
      columnName: 'partNumber',
      wordWrapEnabled: true,
    },
    {
      columnName: 'partDesc',
      wordWrapEnabled: true,
    },

    {
      columnName: 'EngDrawingNo',
      wordWrapEnabled: true,
    },


  ];



  const handlePageIndexChange = (pageIndex: number) => {

    scrollToTop();
  };
  const handlePageSizeChange = (pageSize: number) => {

    scrollToTop();
  };

  return (
    <Paper className={classes.requestContainer}>
      <Box className={classes.gridContainer}>
        <Grid rows={gridRowUpdated} columns={columns}  >
          <EditingState onCommitChanges={() => console.log("clicked")} />

          <PagingState
            currentPage={1}
            onCurrentPageChange={handlePageIndexChange}

            onPageSizeChange={handlePageSizeChange}
          />
          <SortingState

          />
          <DragDropProvider />
          <RowDetailState

          />
          <Table
            tableComponent={ToolDisposalTableComponent}
            columnExtensions={tableColumnExtensions}
            noDataCellComponent={ToolDisposalNoDataCell}
            cellComponent={ToolDisposalCellComponent}
          />
          <TableHeaderRow showSortingControls sortLabelComponent={ToolDisposalSortLabel} />
          <TableColumnVisibility defaultHiddenColumnNames={['id']} />
        </Grid>

      </Box>
      <Box>
        <AddPartModel
          SupplierData={SupplierData}
          totalPartData={totalPartData}
          onConfirmClick={onConfirmClick}
          handlePartNumberChange={handlePartNumberChange}
          disabled={disabled}
        />

      </Box>
    </Paper>
  );
};


const useStyles = makeStyles(() =>
  createStyles({
    requestContainer: {
      '& .MuiTable-root': {
        minWidth: 'inherit !important',
      },
    },
    gridContainer: {
      //height: "400px",
      //overflow: "hidden",
      //overflowY: "scroll",

    },
    button: {
      width: "100%",
      justifyContent: "left",
      background: "#EBF1F7",
      color: "black",
      border: 0,
      borderRadius: 0,
      boxShadow: "none",
      '&:hover': {
        backgroundColor: '#ffffff',
        color: "#004e97",
        boxShadow: 'none',
      },
    },
    groupCell: {
      '& > div': {
        backgroundColor: 'inherit',
      },
    },
  }),
);
