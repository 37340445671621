import React from 'react';
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  body?: string[] | string;
  title?: string;
  subtitle?: string | JSX.Element;
  children?: JSX.Element;
}

export const SnackbarDialog = (props: DialogProps): JSX.Element => {
  const { body, title, subtitle, onClose, open } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby={title} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {body instanceof Array ? (
          <>
            <Typography>{subtitle}</Typography>
            <ul>
              {body.map((item: string) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          </>
        ) : (
          <DialogContentText className={classes.dialogBody}>{body}</DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogBody: {
      color: theme.palette.text.primary,
    },
  }),
);
