import React, { useEffect } from "react";
import {
  Button,
  Box,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Theme,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { debounce } from "lodash";
import { ItoolDisposalFilterset } from "../services/generated/ApiClientGenerated";
import { useDispatch } from "react-redux";
import { useAppData } from "./../services/hooks";
import SearchIcon from "@material-ui/icons/Search";
import {
  setToolDisposalFiltersAction,
  clearToolDisposalFiltersAction,
  requestorAction,
  getAvailableFiltersAction,
} from "./toolDisposalSlice";

const disposalStatus = ["Draft", "In Process", "Approved", "Rejected"];

export const ToolDisposalFilterModule = ({ toolDisposalData }): JSX.Element => {
  const [boolean, setBoolean] = React.useState(true);
  const [assignedTo, setAssignedTo] = React.useState(true);
  const requestorValue = useAppData(
    (r) => r.toolDisposalSlice.requestorInfo.requestorValue.mail
  );

  const filterDate = useAppData(
    (r) => r.toolDisposalSlice.availableFilters.value
  );
  const filters = useAppData((r) => r.toolDisposalSlice.filters);
  const classes = useStyles();
  const dispatch = useDispatch();

  function formatDate(date: any) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function handleFilterChange<TKey extends keyof ItoolDisposalFilterset>(
    key: TKey,
    value: ItoolDisposalFilterset[TKey]
  ) {
    dispatch(setToolDisposalFiltersAction({ key, value }));
  }

  const handleDoubleFilterChange = (
    keyOne: keyof ItoolDisposalFilterset,
    keyTwo: keyof ItoolDisposalFilterset,
    value: ItoolDisposalFilterset[keyof ItoolDisposalFilterset][]
  ) => {
    handleFilterChange(keyOne, value[0]);
    handleFilterChange(keyTwo, value[0]);
  };

  const handleChangeDebounce = debounce((value) => {
    handleFilterChange("SearchTerm", value);
  }, 1000);

  const handleClearFilters = () => {
    dispatch(clearToolDisposalFiltersAction());
  };

  useEffect(() => {
    if (requestorValue != undefined) {
      handleDoubleFilterChange("RequestedBy", "CurrentUserEmail", [
        requestorValue,
      ]);
    }
  }, [requestorValue]);

  useEffect(() => {
    dispatch(requestorAction());
    dispatch(getAvailableFiltersAction());
  }, []);
  let filterDateUpdated = filterDate != undefined ? filterDate : "";
  return (
    <div className={classes.filterWrapper}>
      <Grid
        container
        direction={"column"}
        spacing={2}
        data-tut={"reactour__filter-Module"}
      >
        <Grid item>
          <Autocomplete
            className={classes.textField}
            data-tut={"reactour__Search"}
            id="tool-search"
            freeSolo={true}
            clearOnBlur
            fullWidth
            options={[]}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onInputChange={(event, value) => {
              if (!value) {
                return;
              }
              handleChangeDebounce(value);
            }}
          />
        </Grid>

        <Grid item>
          <Autocomplete
            id="tool-status"
            clearOnBlur
            data-tut={"reactour__Status"}
            aria-label={"part-number-select"}
            fullWidth
            value={
              filters.DisposalStatusId === "1"
                ? "Draft"
                : filters.DisposalStatusId === "2"
                ? "In Process"
                : filters.DisposalStatusId === "3"
                ? "Approved"
                : filters.DisposalStatusId === "4"
                ? "Rejected"
                : ""
            }
            options={disposalStatus}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Status" variant="outlined" />
            )}
            onChange={(event, options) => {
              if (!options) {
                return;
              }
              handleFilterChange(
                "DisposalStatusId",
                options === "Draft"
                  ? "1"
                  : options === "In Process"
                  ? "2"
                  : options === "Approved"
                  ? "3"
                  : options === "Rejected"
                  ? "4"
                  : ""
              );
            }}
          />
        </Grid>

        <Grid item className={classes.toggle}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={filters.AssignedTo === "" ? false : true}
                onChange={() => {
                  if (boolean === true) {
                    setBoolean(false);
                  } else {
                    setBoolean(true);
                  }
                  handleFilterChange(
                    "AssignedTo",
                    boolean ? requestorValue : ""
                  );
                }}
                name="show-only-empty-fields"
                color="primary"
              />
            }
            data-tut={"reactour__myTasks"}
            label="My Tasks"
          />
        </Grid>

        <Grid item className={classes.toggle}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={filters.RequestedBy === "" ? false : true}
                onChange={() => {
                  if (assignedTo) {
                    setAssignedTo(false);
                  } else {
                    setAssignedTo(true);
                  }
                  handleFilterChange(
                    "RequestedBy",
                    !assignedTo ? requestorValue : ""
                  );
                }}
                name="show-only-empty-fields"
                color="primary"
              />
            }
            data-tut={"reactour__myRequests"}
            label="My Requests"
          />
        </Grid>
        <Box component="span" m={1} data-tut={"reactour__StartedLastModified"}>
          <Box mb={2}>
            <Typography> Started / Last Modified Date</Typography>
          </Box>
          <Grid container spacing={2} direction={"column"}>
            <Grid item>
              <TextField
                id="date"
                label="Min Date"
                type="date"
                value={
                  filters.LastModifiedDateMin === ""
                    ? ""
                    : filters.LastModifiedDateMin
                }
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min:
                      filterDateUpdated != "" &&
                      formatDate(
                        new Date(filterDateUpdated.lastModifiedDateMin)
                      ),
                  },
                }}
                onChange={(event) => {
                  const date = event.target.value;
                  handleFilterChange("LastModifiedDateMin", date);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="date"
                label="Max Date "
                type="date"
                fullWidth
                variant="outlined"
                value={
                  filters.LastModifiedDateMax === ""
                    ? ""
                    : filters.LastModifiedDateMax
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max:
                      filterDateUpdated != "" &&
                      formatDate(
                        new Date(filterDateUpdated.lastModifiedDateMax)
                      ),
                  },
                }}
                onChange={(event) => {
                  const date = event.target.value;
                  handleFilterChange("LastModifiedDateMax", date);
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="span" m={1} data-tut={"reactour__dueCompleted"}>
          <Box mb={2}>
            <Typography> Due / Completed Date</Typography>
          </Box>
          <Grid container spacing={2} direction={"column"}>
            <Grid item>
              <TextField
                id="date"
                label="Min Date"
                type="date"
                value={
                  filters.CompletedDateMin === ""
                    ? ""
                    : filters.CompletedDateMin
                }
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min:
                      filterDateUpdated != "" &&
                      formatDate(new Date(filterDateUpdated.completedDateMin)),
                  },
                }}
                onChange={(event) => {
                  const date = event.target.value;
                  handleFilterChange("CompletedDateMin", date);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="date"
                label="Max Date "
                type="date"
                fullWidth
                value={
                  filters.CompletedDateMax === ""
                    ? ""
                    : filters.CompletedDateMax
                }
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min:
                      filterDateUpdated != "" &&
                      formatDate(new Date(filterDateUpdated.completedDateMax)),
                  },
                }}
                onChange={(event) => {
                  const date = event.target.value;
                  handleFilterChange("CompletedDateMax", date);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        className={classes.clearFilterContainer}
      >
        <Button
          data-tut={"reactour__clearFiltersButton"}
          variant="contained"
          color="primary"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
    textField: {
      "& .MuiInputBase-root": {
        paddingRight: "9px !important",
      },
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    clearFilterContainer: {
      marginTop: theme.spacing(2.5),
      display: "flex",
    },
    filterWrapper: {
      "& .MuiAutocomplete-noOptions": {
        color: theme.palette.text.primary,
      },
      margin: theme.spacing(0, 1, 0, 0),
      "& .MuiFormLabel-root": {
        color: theme.palette.secondary.main,
      },
    },
    inputField: {
      width: "6.25rem",
    },
    switch: {
      "& .MuiFormControlLabel-label": {
        lineHeight: "1",
      },
    },
    toggle: {
      margin: theme.spacing(0, 1),
    },
  })
);
