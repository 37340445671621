import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Severity = 'info' | 'success' | 'warning' | 'error';

export type ToastType = {
  isVisible: boolean;
  type: Severity;
  message: string;
};

export type DialogType = {
  title?: string;
  subtitle?: string;
  body?: string[] | string;
  isVisible: boolean;
};

export class User {
  id = '';
  name = '';
  roles = [] as string[];
}

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    toast: <ToastType>{
      isVisible: false,
      type: 'info',
      message: '',
    },
    dialog: <DialogType>{
      title: undefined,
      subtitle: undefined,
      body: undefined,
      isVisible: false,
    },
  },
  reducers: {
    showToastAction: (
      state,
      action: PayloadAction<{
        message: string;
        severity?: Severity;
        title?: string;
        subtitle?: string;
        body?: string[] | string;
      }>,
    ) => {
      state.toast.isVisible = true;
      state.toast.message = action.payload.message;
      state.toast.type = action.payload.severity ?? 'info';
      state.dialog.title = action.payload.title;
      state.dialog.subtitle = action.payload.subtitle;
      state.dialog.body = action.payload.body;
    },
    showDialogAction: (state) => {
      state.dialog.isVisible = true;
    },
    hideDialogAction: (state) => {
      state.dialog.isVisible = false;
      state.dialog.title = '';
      state.dialog.subtitle = '';
      state.dialog.body = '';
    },
    hideToastAction: (state) => {
      state.toast.isVisible = false;
      // state.toast.type = 'info';
      state.toast.message = '';
    },
  },
});

export const { hideDialogAction, hideToastAction, showDialogAction, showToastAction } = globalSlice.actions;
