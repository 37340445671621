import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import Tour, { ReactourStep } from "reactour";
import { useAppData } from "../services/hooks";
import { isDashboardTourOpen } from "./toolDisposalSlice";

export const DashboardWalkthrough: FC = () => {
  const dispatch = useDispatch();
  const isTourOpen = useAppData((r) => r.toolDisposalSlice.isDashboardTourOpen);
  const bodyScrollLock = require("body-scroll-lock");
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const closeTour = () => {
    dispatch(isDashboardTourOpen(false));
  };

  const steps: ReactourStep[] = [
    {
      selector: "[data-tut=reactour__Search]",
      content: "Type here to search by any item that appears in the list view.",
    },
    {
      selector: "[data-tut=reactour__Status]",
      content:
        "Use this filter to sort requests by approval progress (approved, in process, rejected, draft)",
    },
    {
      selector: "[data-tut=reactour__myTasks]",
      content: "Sort by tasks awaiting your approval.",
    },
    {
      selector: "[data-tut=reactour__myRequests]",
      content: "Sort by all requests submitted by you .",
    },
    {
      selector: "[data-tut=reactour__StartedLastModified]",
      content: "Sort by request date.",
    },
    {
      selector: "[data-tut=reactour__dueCompleted]",
      content: "Sort by completion date.",
    },
    {
      selector: "[data-tut=reactour__clearFiltersButton]",
      content: "To remove all applied filters at once, click Clear Filters.",
    },
    {
      selector: "[data-tut=reactour__newRequestButton]",
      content: "Click New Request to start a new tool disposition request.",
    },
    {
      selector: "[data-tut=reactour__downloadButton]",
      content: "Click Download to receive a CSV file.",
    },
    {
      selector: "[data-tut=reactour__printerIcon]",
      content:
        "To create a printable PDF of your data, click the printer icon. If filtering is applied, the PDF will only be narrowed to the items matching your filters.",
    },
    {
      selector: "[data-tut=reactour__logoutButton]",
      content: "Click Logout to sign out of the collection tool.",
    },
    {
      selector: "[data-tut=reactour__helpIcon]",
      content:
        "If at any time you require assistance, either with how to use the tool or questions about the data you are entering, use this button to be led to the appropriate help.",
    },
  ];

  return (
    <Tour
      badgeContent={(curr: number, tot: number) => `${curr} of ${tot}`}
      steps={steps}
      rounded={5}
      isOpen={isTourOpen}
      onRequestClose={closeTour}
      accentColor={"#004e97"}
      prevButton={<ArrowBackIcon aria-label={"go to previous step"} />}
      nextButton={<ArrowForwardIcon aria-label={"go to next step"} />}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
    />
  );
};
