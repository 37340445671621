import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { apiCall, apiClient } from "../services/apiClient";
import { replace,RouterLocation } from "connected-react-router";
import { LocationState } from 'history';
import {
  productLineResponse,
  productLineRequest,
  toolTypeResponse,
  toolTypeRequest,
  approval,
  attachments,
  projectNumberResponse,
  projectNumberRequest,
  supplierResponse,
  countryResponse,
  countryRequest,
  itemResponse,
  toolDisposalRequest,
  tooldisposalResponse,
  toolResponse,
  ItoolDisposalFilterset,
  ToolDisposalFilterset,
  disposalRequestsFilters,
  approverManagers,
} from "../services/generated/ApiClientGenerated";
import { RootState } from "../store";
import { showToastAction } from "../features/globalSlice";
import "react-toastify/dist/ReactToastify.css";
import {
  getAvailableFiltersAction,
  getToolDisposalAction,
  getNewRequest_toolAction,
  getNewRequest_productLinesAction,
  getNewRequest_stateAction,
  getNewRequest_itemsAction,
  gettAttachmentDownloadFileAction,
  updateDisposalRequestAction,
  postAttachmentToRequestIdAction,
  postAttachmentToTempFolderAction,
  postAttachmentToMoveFileAction,
  postAttachmentDeleteFileAction,
  approve_put_Action,
  setToolDisposalPageAction,
  setToolDisposalPageSizeAction,
  setToolDisposalFiltersAction,
  successNewRequest_productLinesAction,
  successNewRequest_toolTypesAction,
  successNewRequest_toolAction,
  successNewRequest_countriesAction,
  successNewRequest_projectNumberAction,
  successNewRequest_suppliersAction,
  successNewRequest_suppliersNumberAction,
  succespostAttachmentAction,
  succespostAttachmentMoveFileAction,
  succespostAttachmentDeleteFileAction,
  succespostAttachmentDownloadFileAction,
  succesAvailableFiltersAction,
  getNewRequest_suppliersAction,
  successNewRequest_statesAction,
  successNewRequest_itemAction,
  successToolDisposalAction,
  successRequestIdUpdateAction,
  succesupdateAndCreateDisposalAction,
  succesApproveRejectResponseAction,
  requestorAction,
  succesRequestorAction,
  getApproversAction,
  succesApproversAction,
} from "./toolDisposalSlice";

export function* getRequestor() {
  try {
    const response: approverManagers = yield apiCall(
      apiClient.getRequestor_Api,
      new approverManagers()
    );
    yield put(succesRequestorAction(response ?? []));
  } catch (err) {
    yield put(replace("/"));
  }
}

export function* getDisposalRequestsAvailableFilters() {
  try {
    const response: disposalRequestsFilters = yield apiCall(
      apiClient.getDisposalRequestsFilters_Get_API,
      new disposalRequestsFilters()
    );
    yield put(succesAvailableFiltersAction(response ?? {}));
  } catch (err) {}
}

export function* getApprovers(action) {
  let data = action.payload;
  try {
    const response: approverManagers = yield apiCall(
      apiClient.getApprovers_Api,
      data
    );

    yield put(succesApproversAction(response ?? []));
  } catch (err) {}
}

export function* postAttachmentToRequestId(action) {
  let data = action.payload;
  try {
    const response: attachments = yield apiCall(
      apiClient.disposalRequests_Attachments_Upload_toRequestId,
      data
    );
    yield put(succespostAttachmentAction(response ?? ""));
  } catch (err) {}
}

export function* postAttachmentTempFolder(action) {
  let data = action.payload;
  try {
    const response: attachments = yield apiCall(
      apiClient.disposalRequests_Attachments_Upload_TempFolder,
      data
    );
    yield put(succespostAttachmentAction(response ?? ""));
  } catch (err) {}
}

export function* postAttachmentMoveFile(action) {
  let data = action.payload;
  try {
    const response: attachments = yield apiCall(
      apiClient.disposalRequests_Attachments_Upload_MoveFile,
      data
    );
    yield put(succespostAttachmentMoveFileAction(response ?? ""));
  } catch (err) {}
}
export function* postAttachmentDeleteFile(action) {
  let data = action.payload;
  try {
    const response: attachments = yield apiCall(
      apiClient.disposalRequests_Attachments_DeleteFile,
      data
    );
    yield put(succespostAttachmentDeleteFileAction(response ?? ""));
  } catch (err) {}
}
export function* gettAttachmentDownloadFile(action) {
  let data = action.payload;
  try {
    const response: attachments = yield apiCall(
      apiClient.disposalRequests_Attachments_Download,
      data
    );
    yield put(succespostAttachmentDownloadFileAction(response ?? ""));
  } catch (err) {}
}

export function* getNewRequest_productLine() {
  try {
    const response: productLineResponse = yield apiCall(
      apiClient.getNewRequest_productLines,
      new productLineRequest()
    );
    yield put(successNewRequest_productLinesAction(response.productLine ?? []));
  } catch (err) {}
}

export function* getNewRequest_toolTypes() {
  try {
    const response: toolTypeResponse = yield apiCall(
      apiClient.getNewRequest_toolTypes,
      new toolTypeRequest()
    );
    yield put(successNewRequest_toolTypesAction(response.toolType ?? []));
  } catch (err) {}
}

export function* getNewRequest_states(action) {
  const bodyData = action.payload;
  try {
    const response: any = yield apiCall(
      apiClient.getNewRequest_states,
      bodyData
    );
    yield put(successNewRequest_statesAction(response.state ?? []));
  } catch (err) {}
}

export function* getNewRequest_projectNumber() {
  try {
    const response: projectNumberResponse = yield apiCall(
      apiClient.getNewRequest_projectNumber,
      new projectNumberRequest()
    );
    yield put(
      successNewRequest_projectNumberAction(response.projectNumber ?? [])
    );
  } catch (err) {}
}

export function* getNewRequest_suppliers(action) {
  const bodyData = action.payload;
  try {
    const response: supplierResponse = yield apiCall(
      apiClient.getNewRequest_suppliers,
      bodyData
    );
    yield put(successNewRequest_suppliersAction(response.supplier ?? []));
  } catch (err) {}
}

export function* getNewRequest_suppliersNumber(action) {
  const bodyData = action.payload;
  try {
    const response: supplierResponse = yield apiCall(
      apiClient.getNewRequest_suppliersNumber,
      bodyData
    );
    yield put(successNewRequest_suppliersNumberAction(response.supplier ?? []));
  } catch (err) {}
}

export function* getNewRequest_countries() {
  try {
    const response: countryResponse = yield apiCall(
      apiClient.getNewRequest_countries,
      new countryRequest()
    );
    yield put(successNewRequest_countriesAction(response.country ?? []));
  } catch (err) {}
}

export function* getNewRequest_item(action) {
  const bodyData = action.payload[0];
  try {
    const response: itemResponse = yield apiCall(
      apiClient.getNewRequest_item,
      bodyData
    );
    yield put(successNewRequest_itemAction(response.item ?? []));
  } catch (err) {}
}
export function* getNewRequest_itemForSupplier(action) {
  // to be deleted if required
  const bodyData = action.payload;
  try {
    const response: itemResponse = yield apiCall(
      //apiClient.getNewRequest_itemForSupplier,
      bodyData
    );
    //yield put(successNewRequest_itemForSupplierAction(response.item ?? []));
  } catch (err) {}
}

export function* getDisposalRequest() {
  try {
    const filters: ItoolDisposalFilterset = yield select(
      (r: RootState) => r.toolDisposalSlice.filters
    );
    const response: tooldisposalResponse = yield apiCall(
      apiClient.getDisposalRequest,
      new ToolDisposalFilterset(filters)
    );
    if (response != null && filters.RequestId === "") {
      yield put(successToolDisposalAction(response.disposalRequest ?? []));
    } else if (response != null && filters.RequestId != "") {
      yield put(successRequestIdUpdateAction(response.disposalRequest ?? []));
      
    }
    else if (response === null && filters.RequestId != "") {
      yield put(replace("/not-found"));
      }
       else if (response === null) {

      yield put(successToolDisposalAction([]));
    }
  } catch (err) {}
}

export function* getNewRequest_tool(action) {
  let data = action.payload;
  try {
    const response: toolResponse = yield apiCall(apiClient.getTool, data);

    yield put(successNewRequest_toolAction(response.tool ?? []));
  } catch (err) {}
}

export function* updateAndCreateDisposalRequest(action: PayloadAction<any>) {
  const oldObject = action.payload;
  const newObject = { ...oldObject };
  const newToolDisposalRequest = new toolDisposalRequest(newObject);
  try {
    const response: tooldisposalResponse = yield apiCall(
      apiClient.disposalRequests_UpdateRecord,
      newToolDisposalRequest
    );
    yield put(
      succesupdateAndCreateDisposalAction(response.disposalRequest ?? [])
    );
    if (response.disposalRequest) {
      yield put(
        showToastAction({
          message: `Successfuly Updated with Request id : ${response.disposalRequest[0].requestId}`,
          severity: "success",
        })
      );
    } else {
    }
  } catch (err) {
    yield put(
      showToastAction({
        message: "Something went wrong.",
        severity: "error",
      })
    );
  }
}

export function* approve_put(action: PayloadAction<any>) {
  const oldObject = action.payload;
  const newObject = { ...oldObject };
  const approvalObj = new approval(newObject);
  try {
    const response: string = yield apiCall(apiClient.approve_put, approvalObj);
    yield put(succesApproveRejectResponseAction(response));

    if (response === "201") {
      if (approvalObj.IsApproved === "true") {
        yield put(
          showToastAction({
            message: `Successfuly Approved for Request id : ${approvalObj.RequestId}`,
            severity: "success",
          })
        );
      } else {
        yield put(
          showToastAction({
            message: `Successfuly Rejected for Request id : ${approvalObj.RequestId}`,
            severity: "success",
          })
        );
      }
    } else {
      yield put(
        showToastAction({
          message: "Something went wrong.",
          severity: "error",
        })
      );
    }
  } catch (err) {}
}
export function* toolDisposalSaga() {
  yield all([
    takeLatest(
      getAvailableFiltersAction.type,
      getDisposalRequestsAvailableFilters
    ),
    takeLatest(getToolDisposalAction.type, getDisposalRequest),
    takeLatest(setToolDisposalPageAction.type, getDisposalRequest),
    takeLatest(setToolDisposalPageSizeAction.type, getDisposalRequest),
    takeLatest(setToolDisposalFiltersAction.type, getDisposalRequest),
    takeLatest(
      getNewRequest_productLinesAction.type,
      getNewRequest_productLine
    ),
    takeLatest(getNewRequest_productLinesAction.type, getNewRequest_toolTypes),
    takeLatest(getNewRequest_toolAction.type, getNewRequest_tool),
    takeLatest(getNewRequest_stateAction.type, getNewRequest_states),
    takeLatest(
      getNewRequest_productLinesAction.type,
      getNewRequest_projectNumber
    ),
    takeLatest(getNewRequest_suppliersAction.type, getNewRequest_suppliers),
    takeLatest(
      getNewRequest_suppliersAction.type,
      getNewRequest_suppliersNumber
    ),
    takeLatest(getNewRequest_productLinesAction.type, getNewRequest_countries),
    takeLatest(getNewRequest_itemsAction.type, getNewRequest_item),
    takeLatest(getNewRequest_itemsAction.type, getNewRequest_itemForSupplier),
    takeLatest(
      updateDisposalRequestAction.type,
      updateAndCreateDisposalRequest
    ),
    takeLatest(approve_put_Action.type, approve_put),
    takeLatest(requestorAction.type, getRequestor),
    takeLatest(getApproversAction.type, getApprovers),
    takeLatest(postAttachmentToRequestIdAction.type, postAttachmentToRequestId),
    takeLatest(postAttachmentToTempFolderAction.type, postAttachmentTempFolder),
    takeLatest(postAttachmentToMoveFileAction.type, postAttachmentMoveFile),
    takeLatest(postAttachmentDeleteFileAction.type, postAttachmentDeleteFile),
    takeLatest(
      gettAttachmentDownloadFileAction.type,
      gettAttachmentDownloadFile
    ),
  ]);
}
