import { Box, createStyles, Grid, lighten, Link, LinkProps, makeStyles, Theme, } from '@material-ui/core';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

export const Nav: FC = () => {
  const classes = useStyles();
    const location = useLocation();

    return (
        <div className={classes.wrapper}>
            <Grid container justifyContent="space-between">
                <Grid container item xs={12} justifyContent="space-between">
                    <Grid container item xs={9} className={classes.navLeft} alignItems="center">
                      
                    </Grid>
                    <Grid xs={3} className={classes.navLeft} item container justifyContent="flex-end" alignItems="center">
                        <Grid item>
                            {
                                location.pathname === "/login" ? null : <Link className={classes.logoutText} href={`${window.location.origin  + "/AzureAdAuth/Logout"}`} data-tut={'reactour__logout'}>
                                    Logout
                                </Link>
                            }
                           
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}; 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      padding: theme.spacing(0, 4),
    },
    [`@media print`]: {
      wrapper: {
        display: 'none',
      },
    },
    tabWrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
    },
    activeTabWrapper: {
      backgroundColor: lighten(theme.palette.primary.main, 0.25),
    },
    activeBreadcrumb: {
      //fontWeight: theme.typography.fontWeightBold,
    },
    navLeft: {
      minHeight: '2.25rem',
      margin: theme.spacing(0, 0),
      '& .MuiTypography-colorPrimary': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    navRight: {
      textAlign: 'right',
      paddingRight: '4rem',
    },
    linksContainer: {
      display: 'flex',
      justifySelf: 'flex-end',
      marginBottom: theme.spacing(3),
    },
    logoutText: {
      justifySelf: 'flex-end',
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);
