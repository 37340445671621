import React, { FC } from 'react';
import { Grid, Box,  Typography } from '@material-ui/core';

type Props = {
  header: string;
};

export const RequestHeader: FC<Props> = (props) => {
  const { header } = props;

    return (
        <Box my={3} >
            <Grid container justifyContent={'center'} alignItems={'center'} style={{ backgroundColor: "#EBF1F7" }}  >
          <Grid item  >
        <Typography variant="h6" component="h1">
          {header}
        </Typography>
      </Grid>
            </Grid>
        </Box>
  );
};
