import React, { FC } from "react";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Button, createStyles, makeStyles, Tooltip } from "@material-ui/core";

const SortingIcon = ({ direction }: any) => {
  return <div>{direction == "asc" ? <ArrowUpward /> : <ArrowDownward />}</div>;
};

export const ToolDisposalSortLabel: FC = ({
  column,
  onSort,
  children,
  direction,
}: any) => {
  const classes = useStyles();

  let tooltip = "";
  let dataTut = "";
  if (column.name === "status") {
    tooltip = `Approval status`;
  } else if (column.name === "toolDescription") {
    tooltip = `Tool asset number and description, if assigned at PT creation`;
  } else if (column.name === "partNumber") {
    tooltip = `Shorts part number produced by tool, if more than one part is produced by this tool, list will display "multi"`;
  } else if (column.name === "requester") {
    tooltip = `Requester name`;
  } else if (column.name === "approverManagers") {
    tooltip = `Current approval needed `;
  }

  return (
    <Tooltip arrow title={tooltip} data-tut={dataTut}>
      <Button size="small" onClick={onSort}>
        {children}
        {direction && (
          <SortingIcon className={classes.icon} direction={direction} />
        )}
      </Button>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      "& .MuiSvgIcon-root": {
        fontSize: "1.125rem",
      },
    },
  })
);
