import React from 'react';
import { TableRowDetail as TableRowDetailBase } from '@devexpress/dx-react-grid';
import { IconButton, TableCell } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export const ToolDisposalToggleCell = ({ onToggle, expanded, ...restProps }: TableRowDetailBase.ToggleCellProps) => {
  return (
    <TableCell {...restProps}>
      <IconButton data-tut={'reactour__'} aria-label={'Toggle Detail Row'} onClick={() => onToggle()}>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    </TableCell>
  );
};
