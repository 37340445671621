import React, { useEffect, useState, useRef } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Button,
  Theme,
  Box,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { useAppData } from "../../services/hooks";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import {
  getNewRequest_suppliersAction,
  submitDataAction,
  getNewRequest_itemsAction,
} from "../toolDisposalSlice";

const partApi = ["53455575"];
export const AddPartModel = ({
  onConfirmClick,
  handlePartNumberChange,
  disabled,
  SupplierData,
  totalPartData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const row = useAppData((r) => r.toolDisposalSlice.updateValues.value);
  const [open, setOpen] = React.useState(false);
  const [supplierNumderAddPart, setSupplierNumderAddPart] = React.useState({
    supplierName: null,
    supplierNumber: null,
  });
  let supplierNumber =
    supplierNumderAddPart && supplierNumderAddPart.supplierNumber;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onConfirm = () => {
    onConfirmClick();
    handleClose();
  };

  const handleSupplierDebounce = debounce((value) => {
    value.length > 2 && dispatch(getNewRequest_suppliersAction(value));
  }, 1000);

  const handlePartNumberDebounce = debounce((supplierNumber, value) => {
    value.length > 2 &&
      dispatch(
        getNewRequest_itemsAction([
          { supplierNumber: supplierNumber, itemNumber: value },
        ])
      );
  }, 1000);

  useEffect(() => {
    if (row.supplierNumber != undefined) {
      let supplierNumderAddPartObj: any = {
        supplierName: row.supplierName,
        supplierNumber: row.supplierNumber,
      };
      setSupplierNumderAddPart(supplierNumderAddPartObj);
    }
  }, [row]);

  return (
    <>
      <div>
        <Button
          className={classes.button1}
          data-tut={"reactour__addPart"}
          variant="contained"
          color="primary"
          disabled={
            row.disposalStatusId === "2" ||
            row.disposalStatusId === "3" ||
            row.disposalStatusId === "4"
              ? true
              : false
          }
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          Add part
        </Button>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card className={classes.root}>
              <CardContent>
                <Box mb={2}>
                  <Typography variant="h5" component="h2">
                    Add Part
                  </Typography>
                </Box>

                <Box px={3}>
                  <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                      <Autocomplete
                        id="Requestor-select"
                        clearOnBlur
                        value={supplierNumderAddPart}
                        aria-label={"Requestor-select"}
                        fullWidth
                        options={SupplierData.map((option) => option)}
                        getOptionLabel={(option: any) =>
                          option.supplierName != null
                            ? `${
                                option.supplierNumber +
                                " - " +
                                option.supplierName
                              }`
                            : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                        onChange={(event, option: any) => {
                          setSupplierNumderAddPart(option);
                        }}
                        onInputChange={(event, value) => {
                          if (!value) {
                            return;
                          }
                          handleSupplierDebounce(value);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        id="Requestor-select"
                        clearOnBlur
                        aria-label={"Requestor-select"}
                        fullWidth
                        multiple
                        options={totalPartData.map((option) => option)}
                        getOptionLabel={(option: any) => option.partNumber}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Part Number"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                        onChange={(event, options) => {
                          if (!options) {
                            return;
                          }
                          handlePartNumberChange("PartNumber", options);
                        }}
                        onInputChange={(event, value) => {
                          if (!value) {
                            return;
                          }
                          handlePartNumberDebounce(supplierNumber, value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box my={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      className={classes.button}
                      data-tut={"reactour__download-Button"}
                      variant="contained"
                      color="primary"
                      disabled={disabled}
                      onClick={onConfirm}
                    >
                      CONFIRM
                    </Button>
                    <Button
                      className={classes.button}
                      data-tut={"reactour__download-Button"}
                      variant="outlined"
                      color="primary"
                      disabled={false}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    root: {
      minWidth: 400,
      padding: 12,
    },

    button: {
      minWidth: "7.188rem",
    },

    textField: {
      "& .MuiFormLabel-root": {
        color: "inherit",
      },
    },
    button1: {
      width: "100%",
      justifyContent: "left",
      background: "#EBF1F7",
      color: "black",
      border: 0,
      borderRadius: 0,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#ffffff",
        color: "#004e97",
        boxShadow: "none",
      },
    },
  })
);
