import { createTheme } from '@material-ui/core/styles';

export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 772,
      md: 919,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#004e97',
      light: '#004e9714',
      dark: 'rgb(0, 54, 105)',
    },
    secondary: {
      main: '#00000090',
    },
    success: {
      main: '#006450',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF',
      hint: '#6C6E74',
    },
    warning: {
      main: '#F5B324',
      contrastText: 'rgb(98, 71, 14)',
      light: 'rgb(254, 247, 233)',
    },
    error: {
      main: '#8A2432',
      contrastText: 'rgb(55, 14, 20)',
      light: 'rgb(243, 233, 234)',
    },
  },
  typography: {
    body1: {
      fontSize: '0.875rem',
    },
    fontFamily: ['Barlow-Regular', 'Tahoma-Regular', 'Arial', 'sans-serif'].join(','),
    h4: {
      textTransform: 'uppercase',
        color: '#004e97',
        fontWeight: "bold",
      fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      },
      h5: {
          textAlign: "center",
          fontWeight:"bold",
          color: '#004e97',
          fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      },
      h6: {
          textAlign: "center",
          fontWeight: "bold",
          color: '#004e97',
          fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        color: '#000000',
        '&$error': {
          color: '#8A2432',
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: '#FFFFFF',
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0 0.125rem 0.313rem',
        fontSize: 11,
      },
    },
  },
});
