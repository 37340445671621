import React, { useEffect, useState, useRef } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAppData } from "../../services/hooks";
import RejectIcon from "../../assets/IconRejected.svg";
import ApprovedIcon from "../../assets/IconApproved.svg";
import { useDispatch } from "react-redux";
import { RequestHeader } from "./newRequestHeader";
import { debounce } from "lodash";
import {
  submitDataAction,
  getApproversAction,
} from "../toolDisposalSlice";
export const BottomSection = ({ paramsData, requester }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerApprovers = "APPROVERS";
  const headerStatus = "STATUS";
  const headerDate = "DATE";
  const headerComments = "COMMENTS";
  const [sourcingValue, setSourcingValue] = useState(null);
  const [engineeringManagerValue, setEngineeringManagerValue] = useState(null);
  const [pGApproverValue, setPGApproverValue] = useState(null);
  const [eolToolingSelectValue, setEolToolingSelectValue] = useState(null);
  const approversValue = useAppData(
    (r) => r.toolDisposalSlice.approvers.approversValue
  );
  const row = useAppData((r) => r.toolDisposalSlice.updateValues.value);
  let filteredapproversValue = approversValue.filter(function (el) {
    return el.mail != row.requester;
  });
  const updateApprovers = filteredapproversValue.map((item) => {
    return {
      name: item.displayName,
      email: item.mail,
    };
  });
 

  const handleChangeDebounce = debounce((value) => {
    dispatch(getApproversAction(value));
  }, 1000);
  const handleApproverManagerchange = (options) => {
    const rowData = { ...row };
    let approveMangers: any = [];
    if (
      rowData &&
      rowData.approverManagers &&
      rowData.approverManagers.approverManager.length > 0
    ) {
      approveMangers = [...rowData.approverManagers.approverManager];
    }

    if (options.role === "Sourcing Manager") {
      if (approveMangers.length === 0) {
        approveMangers.splice(0, 0, options);
      }
      if (approveMangers.length >= 1) {
        approveMangers[0] = options;
      }
    }
    if (options.role === "Engineering Manager") {
      if (approveMangers.length > 0) {
        if (approveMangers.length === 1) {
          approveMangers.splice(1, 0, null, options);
        }
        if (approveMangers.length >= 2) {
          approveMangers[1] = options;
        }
      } else {
        approveMangers.splice(1, 0, null, options);
      }
    }
    if (options.role === "Service Manager") {
      if (approveMangers.length > 0) {
        if (approveMangers.length === 1) {
          approveMangers.splice(1, 0, null, options);
        }
        if (approveMangers.length === 2) {
          approveMangers.splice(2, 0, options);
        }
        if (approveMangers.length >= 3) {
          approveMangers[2] = options;
        }
      } else {
        approveMangers.splice(1, 0, null, null, options);
      }
    }
    if (options.role === "EOL Tooling Asset Manager") {
      if (approveMangers.length > 0) {
        if (approveMangers.length === 1) {
          approveMangers.splice(1, 0, null, null, options);
        }
        if (approveMangers.length === 2) {
          approveMangers.splice(2, 0, null, options);
        }
        if (approveMangers.length === 3) {
          approveMangers.splice(3, 0, options);
        }
        if (approveMangers.length >= 4) {
          approveMangers[3] = options;
        }
      } else {
        approveMangers.splice(1, 0, null, null, null, options);
      }
    }
    let newObj = {};
    newObj["approverManager"] = [...approveMangers];
    rowData.approverManagers = newObj;
    // }
    dispatch(
      submitDataAction({
        body: {
          ...rowData,
        },
      })
    );
  };

  useEffect(() => {
    if (
      row.approverManagers &&
      row.approverManagers?.approverManager?.length > 0 &&
      !engineeringManagerValue
    ) {
      let approverIndexZero = row.approverManagers.approverManager[0];
      let approverIndexOne = row.approverManagers.approverManager[1];
      let approverIndexTwo = row.approverManagers.approverManager[2];
      let approverIndexThree = row.approverManagers.approverManager[3];

      setEngineeringManagerValue(approverIndexOne);
      setSourcingValue(approverIndexZero);
      setPGApproverValue(approverIndexTwo);
      setEolToolingSelectValue(approverIndexThree);
    }
  }, [row, engineeringManagerValue]);
  function formatDate(date: any) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <>
      <Grid container direction={"row"}>
        <Grid className={classes.filterContainer} item xs={12} md={4} lg={4}>
          <Grid item>
            {" "}
            <RequestHeader header={headerApprovers} />{" "}
          </Grid>

          <Box
            data-tut={"reactour__approvers"}
            mr={3}
            style={{ height: "100%" }}
          >
            <Grid
              className={classes.approversWrapper}
              container
              direction={"column"}
              spacing={2}
              style={{ height: "100%" }}
              data-tut={"reactour__filter-Module"}
            >
              <Box sx={{ height: "25%" }}>
                <Autocomplete
                  id="sourcing-manager-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  clearOnBlur
                  aria-label={"sourcing-manager-select"}
                  fullWidth
                  value={sourcingValue}
                  options={updateApprovers}
                  getOptionLabel={(option: any) =>
                    option.name != null
                      ? `${option.name + "   (" + option.email + ")"}`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sourcing Manager"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options: any) => {
                    setSourcingValue(options);
                    handleApproverManagerchange({
                      ...options,
                      role: "Sourcing Manager",
                    });
                  }}
                  onInputChange={(event, value) => {
                    if (!value) {
                      return;
                    }
                    handleChangeDebounce(value);
                  }}
                />
              </Box>

              <Box sx={{ height: "25%" }}>
                <Autocomplete
                  id="engineering-manager-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  clearOnBlur
                  aria-label={"engineering-manager-select"}
                  fullWidth
                  value={engineeringManagerValue}
                  options={updateApprovers}
                  getOptionLabel={(option: any) =>
                    option.name != null
                      ? `${option.name + "   (" + option.email + ")"}`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Engineering Manager "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options: any) => {
                    setEngineeringManagerValue(options);
                    handleApproverManagerchange({
                      ...options,
                      role: "Engineering Manager",
                    });
                  }}
                  onInputChange={(event, value) => {
                    if (!value) {
                      return;
                    }
                    handleChangeDebounce(value);
                  }}
                />
              </Box>

              <Box sx={{ height: "25%" }}>
                <Autocomplete
                  id="service-manager-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  clearOnBlur
                  aria-label={"service-manager-select"}
                  fullWidth
                  value={pGApproverValue}
                  options={updateApprovers}
                  getOptionLabel={(option: any) =>
                    option.name != null
                      ? `${option.name + "   (" + option.email + ")"}`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"PG&A Approver"}
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options) => {
                    if (!options) {
                      return;
                    }
                    setPGApproverValue(options);
                    handleApproverManagerchange({
                      ...options,
                      role: "Service Manager",
                    });
                  }}
                  onInputChange={(event, value) => {
                    if (!value) {
                      return;
                    }
                    handleChangeDebounce(value);
                  }}
                />
              </Box>

              <Box sx={{ height: "25%" }}>
                <Autocomplete
                  id="eol-tooling-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  clearOnBlur
                  aria-label={"eol-tooling-select"}
                  fullWidth
                  value={eolToolingSelectValue}
                  options={updateApprovers}
                  getOptionLabel={(option: any) =>
                    option.name != null
                      ? `${option.name + "   (" + option.email + ")"}`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="EOL Tooling Asset Manager"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options) => {
                    if (!options) {
                      return;
                    }
                    setEolToolingSelectValue(options);
                    handleApproverManagerchange({
                      ...options,
                      role: "EOL Tooling Asset Manager",
                    });
                  }}
                  onInputChange={(event, value) => {
                    if (!value) {
                      return;
                    }
                    handleChangeDebounce(value);
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid className={classes.filterContainer} item>
          <Grid item>
            {" "}
            <RequestHeader header={headerStatus} />{" "}
          </Grid>

          <Box
            data-tut={"reactour__approvers"}
            mr={3}
            style={{ height: "100%" }}
          >
            <Grid
              className={classes.approversWrapper}
              container
              direction={"column"}
              spacing={2}
              style={{ height: "100%" }}
              data-tut={"reactour__filter-Module"}
            >
              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[0].disposalStatusId ===
                    null ? (
                    "-"
                  ) : row &&
                    row.approverManagers &&
                    row.approverManagers.approverManager[0].disposalStatusId ===
                      "3" ? (
                    <img src={ApprovedIcon} />
                  ) : (
                    <img src={RejectIcon} />
                  )}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[1].disposalStatusId ===
                    null ? (
                    "-"
                  ) : row &&
                    row.approverManagers &&
                    row.approverManagers.approverManager[1].disposalStatusId ===
                      "3" ? (
                    <img src={ApprovedIcon} />
                  ) : (
                    <img src={RejectIcon} />
                  )}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[2].disposalStatusId ===
                    null ? (
                    "-"
                  ) : row &&
                    row.approverManagers &&
                    row.approverManagers.approverManager[2].disposalStatusId ===
                      "3" ? (
                    <img src={ApprovedIcon} />
                  ) : (
                    <img src={RejectIcon} />
                  )}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[3].disposalStatusId ===
                    null ? (
                    "-"
                  ) : row &&
                    row.approverManagers &&
                    row.approverManagers.approverManager[3].disposalStatusId ===
                      "3" ? (
                    <img src={ApprovedIcon} />
                  ) : (
                    <img src={RejectIcon} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid className={classes.filterContainer} item xs={12} md={3} lg={3}>
          <Grid item>
            {" "}
            <RequestHeader header={headerDate} />{" "}
          </Grid>

          <Box
            data-tut={"reactour__approvers"}
            mr={3}
            style={{ height: "100%" }}
          >
            <Grid
              className={classes.approversWrapper}
              container
              direction={"column"}
              spacing={2}
              style={{ height: "100%" }}
              data-tut={"reactour__filter-Module"}
            >
              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[0].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      formatDate(
                        new Date(
                          row.approverManagers.approverManager[0].actionDate
                        )
                      )}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[1].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      formatDate(
                        new Date(
                          row.approverManagers.approverManager[1].actionDate
                        )
                      )}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[2].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      formatDate(
                        new Date(
                          row.approverManagers.approverManager[2].actionDate
                        )
                      )}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[3].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      formatDate(
                        new Date(
                          row.approverManagers.approverManager[3].actionDate
                        )
                      )}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid className={classes.filterContainer} item xs={12} md={5} lg={4}>
          <Grid item>
            {" "}
            <RequestHeader header={headerComments} />{" "}
          </Grid>

          <Box
            data-tut={"reactour__approvers"}
            mr={3}
            style={{ height: "100%" }}
          >
            <Grid
              className={classes.approversWrapper}
              container
              direction={"column"}
              spacing={2}
              style={{ height: "100%" }}
              data-tut={"reactour__filter-Module"}
            >
              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[0].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      row.approverManagers.approverManager[0].comments}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[1].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      row.approverManagers.approverManager[1].comments}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[2].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      row.approverManagers.approverManager[2].comments}
                </Box>
              </Box>

              <Box sx={{ height: "25%" }} className={classes.boxAlign}>
                <Box my={2}>
                  {row &&
                  row.approverManagers &&
                  row.approverManagers.approverManager[3].actionDate === null
                    ? "-"
                    : row &&
                      row.approverManagers &&
                      row.approverManagers.approverManager[3].comments}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    approversWrapper: {
      "& .MuiAutocomplete-noOptions": {
        color: theme.palette.text.primary,
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.secondary.main,
      },
    },

    filterContainer: {
      //
      marginBottom: theme.spacing(2),
    },
    boxAlign: {
      alignSelf: "center",
    },
    approversColumn: {
      "& .MuiBox-root.MuiBox-root-126": {
        justifyContent: "left",
        alignSelf: "center",
      },
      height: "100%",
    },
  })
);
