import createSagaMiddleware from "@redux-saga/core";
import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, History, LocationState } from "history";

import { createRootReducer } from "./reducer";
import { rootSagas } from "./sagas";

export const history = createBrowserHistory<string>();

const sagaMiddleware = createSagaMiddleware();

export function createStore<L = LocationState>(
  history: History<L>,
  options: Partial<ConfigureStoreOptions> = {}
) {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .prepend(routerMiddleware(history))
        .concat(sagaMiddleware),
    reducer: createRootReducer(history),
    devTools: process.env.NODE_ENV !== "production",
    ...options,
  });
}

export const store = createStore(history);

sagaMiddleware.run(rootSagas);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootAction = ReturnType<AppDispatch>;
