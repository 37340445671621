import React, { FC } from "react";
import { Button, createStyles, makeStyles, Tooltip } from "@material-ui/core";

export const ToolDisposalSortLabel: FC = ({
  column,
  onSort,
  children,
}: any) => {
  let tooltip = "";
  let dataTut = "";
  if (column.name === "[partNumber") {
    tooltip = `Tool tip Text`;
  }

  return (
    <Tooltip arrow title={tooltip} data-tut={dataTut}>
      <Button size="small" onClick={onSort}>
        {children}
      </Button>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    editIcon: {
      color: theme.palette.text.secondary,
      fontSize: "1.125rem",
    },
    icon: {
      "& .MuiSvgIcon-root": {
        fontSize: "1.125rem",
      },
    },
  })
);
