import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const ToolDisposalTableRow = ({ row, ...restProps }: any) => {
   
  const classes = useStyles();

  return (
    <>
      <Table.Row
        className={row.demandUtilization >= 1.5 ? classes.error : row.demandUtilization >= 1.1 ? classes.warning : ''}
        {...restProps}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    safe: {},
    error: {
      '& .MuiTableCell-body': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: `${theme.palette.error.main}`,
      },
      '& .MuiIconButton-label': {
        color: theme.palette.text.secondary,
      },
    },
    warning: {
      '& .MuiTableCell-body': {
        color: theme.palette.warning.contrastText,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: `${theme.palette.warning.light}`,
      },
      '& .MuiIconButton-label': {
        color: theme.palette.warning.contrastText,
      },
    },
  }),
);
