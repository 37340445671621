import {
  Container,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { ToolDisposalPage } from "./toolDisposalRequests/toolDisposalPage";
import { NotFoundPage } from "./notFound";
import { NewRequestPage } from "./toolDisposalRequests/newRequestToolDisposal/newRequestPage";
import { isDashboardTourOpen } from "../src/toolDisposalRequests/toolDisposalSlice";
import { isNewRequestTourOpen } from "../src/toolDisposalRequests/toolDisposalSlice";

import { useDispatch } from "react-redux";

export const AppRoutes: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const visited = localStorage["alreadyVisited"];
    const visitedRequest = localStorage["alreadyVisitedRequestPage"];
    if (!visited) {
      localStorage["alreadyVisited"] = true;
      dispatch(isDashboardTourOpen(true));
    }
    if (!visitedRequest) {
      localStorage["alreadyVisitedRequestPage"] = true;
      dispatch(isNewRequestTourOpen(true));
    }
  }, []);

  return (
    <Container className={classes.root} maxWidth={"xl"}>
      <Route path="*">
        <>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/tool-disposal" component={ToolDisposalPage} />
            <Route path="/disposal-request" component={NewRequestPage} />

            <Route exact path={"/not-found"} component={NotFoundPage} />
            <Redirect to={"/not-found"} path="*" />
          </Switch>
        </>
      </Route>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "& .shepherd-theme-custom": {
      background: "white !important",
    },
    "& .shepherd-content": {
      background: "white",
    },
    root: {
      maxWidth: "1440px",
      margin: "auto",
      padding: theme.spacing(3),
    },
  })
);
