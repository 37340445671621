import React, { FC } from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

import { CircularProgress as CircularProgressSpinner } from '@material-ui/core';

export type CircularProgressType = {
  color?: 'primary' | 'secondary' | 'inherit' | undefined;
  size?: string;
};

export const CircularProgress: FC<CircularProgressType> = ({ color, size }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.circularProgressContainer}>
      <CircularProgressSpinner aria-label={'circular-indeterminate-progress'} color={color} size={size} />
    </Grid>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    circularProgressContainer: {
      display: 'flex',
    },
  }),
);
