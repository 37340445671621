import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

export const Footer: FC = () => {
  const classes = useStyles();

  return <p className={classes.footer}>&copy; {new Date().getFullYear()} Polaris</p>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      textAlign: 'right',
      padding: theme.spacing(0.5, 1.5),
    },
    [`@media print`]: {
      footer: {
        display: 'none',
      },
    },
  }),
);
