
import React from 'react';
import {
    createStyles, Grid, makeStyles, Button, Theme, Box, TextField,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

export const ApprovalNotesModel = ({ setComment, disabled, title, startIcon, onClickConfirmButton }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const onClickConfirm = () => {
        setOpen(false);
        onClickConfirmButton()
    };
    const approvalText = (data) => {
        setText(data)
        setComment(data);
    }

    return (
        <>
            <div>
                <Button
                    onClick={handleOpen}
                    color={'primary'}
                    disabled={disabled}
                    startIcon={startIcon}
                    variant="contained"
                    className={classes.button}
                    data-tut={'reactour__download-Button'}
                >
                    {title}
                </Button>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h2">
                                        {title + "   " +"Request"}
                                    </Typography>
                                </Box>

                                <Box px={2}>

                                    <TextField
                                        className={classes.textField}
                                        error={false}
                                        autoFocus
                                        helperText={
                                            false
                                                ? 'You have reached the maximum character limit'
                                                : null
                                        }
                                        inputProps={{ maxLength: 300 }}
                                        multiline
                                        variant="outlined"
                                        margin="dense"
                                        id="id"
                                        label="Comments"
                                        rows={4}
                                        type="text"
                                        fullWidth
                                        onChange={(e) => {
                                            approvalText(e.target.value)
                                        }}
                                    />

                                </Box>




                                <Box my={2}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Button
                                            className={classes.button}
                                            data-tut={'reactour__download-Button'}
                                            variant="contained"
                                            color="primary"
                                            disabled={text === "" ? true : false}
                                            onClick={onClickConfirm}

                                        >
                                            CONFIRM
                                        </Button>
                                        <Button
                                            className={classes.button}
                                            data-tut={'reactour__download-Button'}
                                            variant="outlined"
                                            color="primary"
                                            disabled={false}
                                            onClick={handleClose}

                                        >
                                            Cancel
                                        </Button>
                                    </Grid>

                                </Box>
                            </CardContent>

                        </Card>

                    </Fade>
                </Modal>
            </div>

        </>
    );
};




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },


        root: {
            minWidth: 300,
            padding: 12,
        },

        button: {
            minWidth: '7.188rem',
        },
        textField: {
            "& .MuiFormLabel-root": {
              color: "inherit",
            },
            minWidth: 300,
            padding: 12,
          },






    }),
);

