import { Table as TableBase } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Theme, Typography } from '@material-ui/core';

export const ToolDisposalNoDataCell = (props: TableBase.NoDataCellProps): JSX.Element => {
  const classes = useStyles();
  const loading = true;
  const toolId = "";
  const showOnlyNull = "";

  return loading ? (
    <td colSpan={props.colSpan} className={classes.tableRow} />
  ) : !toolId.length ? (
    <td colSpan={props.colSpan} className={classes.tableRow}>
      <Typography component={'h2'} variant={'subtitle1'}>
        Please select a tool
      </Typography>
    </td>
  ) : showOnlyNull ? (
    <td colSpan={props.colSpan} className={classes.tableRow}>
      <Typography component={'h2'} variant={'subtitle1'}>
        There are currently no empty fields
      </Typography>
    </td>
  ) : (
    <Table.NoDataCell {...props} />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      textAlign: 'center',
      padding: theme.spacing(5, 0, 0),
    },
  }),
);
