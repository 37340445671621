import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  IconButton,
  Box,
  TextField,
  Theme,
  Link,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "../../components/reusable/circularProgress";
import { RequestHeader } from "./newRequestHeader";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
import { useAppData } from "../../services/hooks";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import {
  getNewRequest_toolAction,
  submitDataAction,
  getApproversAction,
  gettAttachmentDownloadFileAction,
  postAttachmentToRequestIdAction,
  postAttachmentToTempFolderAction,
  postAttachmentToMoveFileAction,
  succespostAttachmentMoveFileAction,
  postAttachmentDeleteFileAction,
  updateDisposalRequestAction,
  succespostAttachmentDownloadFileAction,
} from "../toolDisposalSlice";
import { IAttachments } from "../../services/generated/ApiClientGenerated";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { v4 as uuidv4 } from "uuid";
import { CentralLoader } from "./centralLoaderModal";
var FileSaver = require("file-saver");


const toolSelectOptions = ["Search", "Write-In", "No tool Number"];

const businessUnit = [
  { businessUnit: "Chassis" },
  { businessUnit: "PT" },
  { businessUnit: "Accessories" },
];
export const LeftSection = ({
  paramsData,
  requester,
  statusSubmit,
}): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const toolValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.toolValue
  );
  const countriesValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.countriesValue
  );
  const attachmentsResponse = useAppData((r) =>
    r.toolDisposalSlice.attachmentsResponse.originalAttachmentValue
      ? r.toolDisposalSlice.attachmentsResponse.originalAttachmentValue
      : ""
  );
  const moveAttachmentResponse = useAppData((r) =>
    r.toolDisposalSlice.attachmentsResponse.moveAttachmentValue
      ? r.toolDisposalSlice.attachmentsResponse.moveAttachmentValue
      : ""
  );
  const deleteAttachmentResponse = useAppData((r) =>
    r.toolDisposalSlice.attachmentsResponse.deleteAttachmentValue
      ? r.toolDisposalSlice.attachmentsResponse.deleteAttachmentValue
      : ""
  );
  const downloadAttachmentValue = useAppData((r) =>
    r.toolDisposalSlice.attachmentsResponse.downloadAttachmentValue
      ? r.toolDisposalSlice.attachmentsResponse.downloadAttachmentValue
      : ""
  );
  const isAttachLoading = useAppData(
    (r) => r.toolDisposalSlice.attachmentsResponse.loading
  );

  
  const approversValue = useAppData(
    (r) => r.toolDisposalSlice.approvers.approversValue
  );
  const row = useAppData((r) => r.toolDisposalSlice.updateValues.value);
  const updateCreateToolResponse = useAppData(
    (r) => r.toolDisposalSlice.updateCreateToolResponse.requestId
  );
  let attachmentRowData =
    row && row.attachments ? row.attachments.attachment : [];

  const header = "REQUEST INFORMATION";
  const headerApprovers = "APPROVERS";
  const headerAttachments = "ATTACHMENTS";
  const [bindingData, setBindingData] = useState({});
  const [arrayAttachment, setArrayAttachment] = useState([]);
  const [attachment, setAttachment] = useState({});
  const [guiID, setGuiID] = useState({});
  const [deletdAttachment, setDeletdAttachment] = useState(null);
  const [modalActive, setModalActive] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [toolSelectOption, setToolSelectOption] = React.useState("notSelected");

  const dispatch = useDispatch();
  useEffect(() => {
    let tempRequestId: any = uuidv4();
    setGuiID(tempRequestId);
  }, []);

  useEffect(() => {
    if (downloadAttachmentValue != "") {
      FileSaver.saveAs(downloadAttachmentValue);
    }
    dispatch(succespostAttachmentDownloadFileAction(""));
  }, [downloadAttachmentValue]);

  useEffect(() => {
    if (attachmentsResponse != "") {
      let splitAttachments = attachmentsResponse.split("/");
      let AttachmentRow: any = {
        attachmentName: splitAttachments[2],
        fileAbsoluteUrl: attachmentsResponse,
      };
      setAttachment(AttachmentRow);
    }
  }, [attachmentsResponse]);
  useEffect(() => {
    handleAttachments(attachment);

    
  }, [attachment]);

  const createTagHandler =
    (
      tagType:
        | "requester"
        | "businessUnit"
        | "approverManagers"
        | "reasonForRequest"
        | "toolNumber"
        | "toolNumberWrite"
        | "handleToolDescriptionStatic"
        | "toolDescription"
        | "attachments"
        | "toolNumberStatic"
    ) =>
    (value: any) => {
      const { ...rowdata } = row;
      if (tagType === "toolNumber") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              ...bindingData,
            },
          })
        );
      } else if (tagType === "toolNumberStatic") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              toolNumber: value[0],
              toolDescription: value[1],
            },
          })
        );
      } else if (tagType === "toolNumberWrite") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              toolNumber: value,
            },
          })
        );
      } else if (tagType === "toolDescription") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              toolDescription: value,
            },
          })
        );
      } else if (tagType === "attachments") {
        if (row && row.attachments && row.attachments.attachment.length > 0) {
          dispatch(
            submitDataAction({
              body: {
                ...rowdata,
                attachments: {
                  attachment: [...row.attachments.attachment, attachment],
                },
              },
            })
          );
        } else {
          dispatch(
            submitDataAction({
              body: {
                ...rowdata,
                attachments: {
                  attachment: [attachment],
                },
              },
            })
          );
        }
      } else {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              [tagType]: value,
            },
          })
        );
      }
    };

  const handleToolNumberWrite = createTagHandler("toolNumberWrite");
  const handleBusinessUnit = createTagHandler("businessUnit");
  const handleAttachments = createTagHandler("attachments");
  const handleToolNumber = createTagHandler("toolNumber");
  const handleToolNumberStatic = createTagHandler("toolNumberStatic");
  const handleToolDescriptionStatic = createTagHandler("toolDescription");
  const handleReasonForRequest = createTagHandler("reasonForRequest");
  const [sourceing, setSourceing] = React.useState({});
  const [engineering, setEngineering] = React.useState({});
  const [service, setService] = React.useState({});
  const [eol, setEol] = React.useState({});
  const [indexAttachment, setIndexAttachment] = React.useState(0);
  const [sourcingValue, setSourcingValue] = useState(null);
  const [engineeringManagerValue, setEngineeringManagerValue] = useState(null);
  const [pGApproverValue, setPGApproverValue] = useState(null);
  const [eolToolingSelectValue, setEolToolingSelectValue] = useState(null);

  let newObj = toolValue.map(function (item, key) {
    return {
      id: key,
      toolNumber: item.toolNumber,
      toolDescription: item.toolDescription,
      numberAndDiscription: `${item.toolNumber} - ${item.toolDescription}`,
    };
  });

  function approverObject(this: any, email, role, name) {
    this.Email = email;
    this.Role = role;
    this.Name = name;
  }

  let mainData = { ApproverManager: [sourceing, engineering, service, eol] };

  function handleFilterChange(options: any) {
    let newToolValuFiltered = toolValue.filter((obj) => {
      return obj.toolNumber === options.toolNumber;
    });
    let data = newToolValuFiltered[0];
    let countryName = countriesValue.filter((obj) => {
      return obj.code === data.toolLocation.country;
    });
   
    let newToolBindingData = {
      supplierName: data.vendorName,
      supplierNumber: data.vendorNo,
      poNumber: data.toolValue.value,
      productLines: data.productGroup,
      countryToolResidesIn: data.toolLocation.country,
      stateToolResidesIn: data.toolLocation.state,
      toolTypeId: data.toolTypeId,
      toolType: data.toolType,
      totalPOValue: data.toolValue.poActualAmount,
      projectNumber: data.projectNumber,
      toolNumber: data.toolNumber,
      toolDescription: data.toolDescription,
    };

    setBindingData(newToolBindingData);
  }

  let attachmentsArray: any =
    row &&
    row.attachments &&
    row.attachments.attachment &&
    row.attachments.attachment.map((e) => {
      return {
        tempRequestId: `temprequestid-${guiID}`,
        originalRequestId: updateCreateToolResponse,
        fileName: e.attachmentName,
        action: "movetempfile",
      };
    });



  const handleClose = () => {
    setModalActive(false);
    setModalOpen(false);
  };

  useEffect(() => {
    const attachmentMove = () => {
      if (
        attachmentsArray &&
        attachmentsArray.length > 0 &&
        attachmentsArray.length + 1 > indexAttachment &&
        row &&
        row.attachments &&
        row.attachments.attachment &&
        row.attachments.attachment[0].fileAbsoluteUrl.match("temprequestid")
      ) {
        setModalOpen(true);
        setModalActive(true);
        if (
          moveAttachmentResponse === "204" ||
          moveAttachmentResponse === "empty"
        ) {
          if (attachmentsArray.length > indexAttachment) {
            dispatch(
              postAttachmentToMoveFileAction(attachmentsArray[indexAttachment])
            );
          }
          if (moveAttachmentResponse === "204") {
            let data: any = {
              attachmentName: attachmentsArray[indexAttachment - 1].fileName,
              fileAbsoluteUrl: `attachments/requestid-${updateCreateToolResponse}/${
                attachmentsArray[indexAttachment - 1].fileName
              }`,
            };
            let updatedArray: any = [...arrayAttachment, data];
            setArrayAttachment(updatedArray);
          }
          setIndexAttachment(indexAttachment + 1);
        }

        dispatch(succespostAttachmentMoveFileAction(""));
      }
    };
    setTimeout(attachmentMove, 1000);
  }, [updateCreateToolResponse, moveAttachmentResponse === "204"]);
  useEffect(() => {
    if (attachmentsArray != undefined) {
      const rowData = { ...row };
      dispatch(
        submitDataAction({
          body: {
            ...rowData,
            attachments: {
              attachment: [...arrayAttachment],
            },
          },
        })
      );
      if (statusSubmit === true) {
        let updatedRow = {
          ...row,
          disposalStatusId: "2",
          attachments: {
            attachment: [...arrayAttachment],
          },
        };
        dispatch(updateDisposalRequestAction(updatedRow));
      } else {
        let updatedRow = {
          ...row,
          disposalStatusId: "1",
          attachments: {
            attachment: [...arrayAttachment],
          },
        };
        dispatch(updateDisposalRequestAction(updatedRow));
      }
    }

    setModalOpen(false);
    setModalActive(false);
    setArrayAttachment([]);
  }, [(attachmentsArray && attachmentsArray.length + 1) === indexAttachment]);

  useEffect(() => {
    if (deleteAttachmentResponse === "204") {
      let data = attachmentRowData.filter(
        (_, index) => index !== deletdAttachment
      );
      const rowData = { ...row };
      dispatch(
        submitDataAction({
          body: {
            ...rowData,
            attachments: {
              attachment: [...data],
            },
          },
        })
      );
      if (row && row.requestId) {
        let updatedRow = {
          ...row,
          disposalStatusId: "1",
          attachments: {
            attachment: [...data],
          },
        };
        dispatch(updateDisposalRequestAction(updatedRow));
      }
    } else {
    }
  }, [deleteAttachmentResponse === "204"]);

  useEffect(() => {
    handleToolNumber(bindingData);
  }, [bindingData]);

  useEffect(() => {
    if (updateCreateToolResponse != undefined) {
      history.replace(`${"/disposal-request/" + updateCreateToolResponse}`);
    }
  }, [updateCreateToolResponse]);

  const handleChangeDebounce = debounce((value) => {
    dispatch(getApproversAction(value));
  }, 1000);

  const handleToolDebounce = debounce((value) => {
    value.length > 2 && dispatch(getNewRequest_toolAction(value));
  }, 1000);

  let filteredapproversValue = approversValue.filter(function (el) {
    return el.mail != row.requester;
  });
  const updateApprovers = filteredapproversValue.map((item) => {
    return {
      name: item.displayName,
      email: item.mail,
    };
  });
 

  useEffect(() => {
    if (
      row.approverManagers &&
      row.approverManagers?.approverManager?.length > 0 &&
      !engineeringManagerValue
    ) {
      let approverIndexZero = row.approverManagers.approverManager[0];
      let approverIndexOne = row.approverManagers.approverManager[1];
      let approverIndexTwo = row.approverManagers.approverManager[2];
      let approverIndexThree = row.approverManagers.approverManager[3];

      setEngineeringManagerValue(approverIndexOne);
      setSourcingValue(approverIndexZero);
      setPGApproverValue(approverIndexTwo);
      setEolToolingSelectValue(approverIndexThree);
    }
  }, [row, engineeringManagerValue]);

  const handleApproverManagerchange = (options) => {
    const rowData = { ...row };
    let approveMangers: any = [];
    if (
      rowData &&
      rowData.approverManagers &&
      rowData.approverManagers.approverManager.length > 0
    ) {
      approveMangers = [...rowData.approverManagers.approverManager];
    }

    if (options.role === "Sourcing Manager") {
      if (approveMangers.length === 0) {
        approveMangers.splice(0, 0, options);
      }
      if (approveMangers.length >= 1) {
        approveMangers[0] = options;
      }
    }
    if (options.role === "Engineering Manager") {
      if (approveMangers.length > 0) {
        if (approveMangers.length === 1) {
          approveMangers.splice(1, 0, null, options);
        }
        if (approveMangers.length >= 2) {
          approveMangers[1] = options;
        }
      } else {
        approveMangers.splice(1, 0, null, options);
      }
    }
    if (options.role === "Service Manager") {
      if (approveMangers.length > 0) {
        if (approveMangers.length === 1) {
          approveMangers.splice(1, 0, null, options);
        }
        if (approveMangers.length === 2) {
          approveMangers.splice(2, 0, options);
        }
        if (approveMangers.length >= 3) {
          approveMangers[2] = options;
        }
      } else {
        approveMangers.splice(1, 0, null, null, options);
      }
    }
    if (options.role === "EOL Tooling Asset Manager") {
      if (approveMangers.length > 0) {
        if (approveMangers.length === 1) {
          approveMangers.splice(1, 0, null, null, options);
        }
        if (approveMangers.length === 2) {
          approveMangers.splice(2, 0, null, options);
        }
        if (approveMangers.length === 3) {
          approveMangers.splice(3, 0, options);
        }
        if (approveMangers.length >= 4) {
          approveMangers[3] = options;
        }
      } else {
        approveMangers.splice(1, 0, null, null, null, options);
      }
    }
    let newObj = {};
    newObj["approverManager"] = [...approveMangers];
    rowData.approverManagers = newObj;
    dispatch(
      submitDataAction({
        body: {
          ...rowData,
        },
      })
    );
  };


  const inputRef: any = useRef(null);

  const onClickAttachments = () => {
    inputRef.current.click();
  };

  function handleFileChange(
    file: IAttachments,
    originalRequestId: IAttachments
  ) {
    dispatch(postAttachmentToRequestIdAction({ file, originalRequestId }));
  }

  function handleTempFileChange(
    file: IAttachments,
    tempRequestId: IAttachments
  ) {
    dispatch(postAttachmentToTempFolderAction({ file, tempRequestId }));
  }

  function deleteAttachmentFile(data, index) {
    let splitAttachments = data.fileAbsoluteUrl.split("/");
    if (splitAttachments[1].match("temp")) {
      dispatch(
        postAttachmentDeleteFileAction({
          tempRequestId: splitAttachments[1],
          originalRequestId: "",
          fileName: splitAttachments[2],
          action: "deletefile",
        })
      );

      setDeletdAttachment(index);
    } else {
      dispatch(
        postAttachmentDeleteFileAction({
          tempRequestId: "",
          originalRequestId: splitAttachments[1],
          fileName: splitAttachments[2],
          action: "deletefile",
        })
      );
      setDeletdAttachment(index);
    }
  }
  function downloadAttachmentFile(data, index) {
    let splitAttachments = data.fileAbsoluteUrl.split("/");
    if (splitAttachments[1].match("temp")) {
      dispatch(
        gettAttachmentDownloadFileAction({
          tempRequestId: splitAttachments[1],
          originalRequestId: "",
          fileName: splitAttachments[2],
        })
      );
    } else {
      let originalRequest = splitAttachments[1].split("-");
      dispatch(
        gettAttachmentDownloadFileAction({
          originalRequestId: originalRequest[1],
          tempRequestId: "",
          fileName: splitAttachments[2],
        })
      );
    }
  }

  return (
    <div className={classes.filterWrapper}>
      {modalActive && (
        <CentralLoader open={modalOpen} handleClose={handleClose} />
      )}
      <Box data-tut={"reactour__requestInformation"}>
        <Grid item>
          {" "}
          <RequestHeader header={header} />{" "}
        </Grid>
        <Grid container direction={"column"} spacing={2}>
          {row && row.requestId && (
            <Grid item>
              <TextField
                disabled
                fullWidth
                label="Request Id"
                variant="outlined"
                value={"Request Id : " + row.requestId}
              />
            </Grid>
          )}
          {row && row.requester && (
            <Grid item>
              <TextField
                disabled
                fullWidth
                label="Requestor"
                variant="outlined"
                value={row.requester}
              />
            </Grid>
          )}
          <Grid item>
            <Autocomplete
              id="business-unit-select"
              disabled={
                paramsData.disposalStatusId === "2" ||
                paramsData.disposalStatusId === "3" ||
                paramsData.disposalStatusId === "4" ||
                (!requester &&
                  (paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4"))
                  ? true
                  : false
              }
              value={row}
              clearOnBlur
              aria-label={"business-unit-select"}
              fullWidth
              options={businessUnit}
              getOptionLabel={(option) => option.businessUnit}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Business Unit"
                  variant="outlined"
                />
              )}
              onChange={(event, options) => {
                if (!options) {
                  return;
                }
                handleBusinessUnit(options.businessUnit);
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              id="tool-option-select"
              disabled={
                paramsData.disposalStatusId === "2" ||
                paramsData.disposalStatusId === "3" ||
                paramsData.disposalStatusId === "4" ||
                (!requester &&
                  (paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4"))
                  ? true
                  : false
              }
              clearOnBlur
              aria-label={"tool-option-select"}
              fullWidth
              options={toolSelectOptions}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tool Select Option"
                  variant="outlined"
                />
              )}
              onChange={(event, options) => {
                if (!options) {
                  return;
                } else if (options === "No tool Number") {
                  handleToolNumberStatic([
                    "No tool Number",
                    "No tool description",
                  ]);
                } else {
                  handleToolNumberStatic([null, null]);
                }
                setToolSelectOption(options);
              }}
            />
          </Grid>
          {toolSelectOption === "Write-In" ? (
            <>
              <Grid item>
                <TextField
                  fullWidth
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value={
                    row.toolNumber === null || row.toolNumber === undefined
                      ? ""
                      : row.toolNumber
                  }
                  label="Tool Number"
                  variant="outlined"
                  onChange={(event) =>
                    handleToolNumberWrite(event.target.value as string)
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value={
                    row.toolNumber === null || row.toolNumber === undefined
                      ? ""
                      : row.toolDescription
                  }
                  label="Tool Description"
                  variant="outlined"
                  onChange={(event) =>
                    handleToolDescriptionStatic(event.target.value as string)
                  }
                />
              </Grid>
            </>
          ) : toolSelectOption === "No tool Number" ? (
            <>
              <Grid item>
                <TextField
                  fullWidth
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value="No tool Number"
                  label="Tool Number"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value="No tool Description"
                  label="Tool Description"
                  variant="outlined"
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <Autocomplete
                id="tool-number-description-select"
                disabled={
                  paramsData.disposalStatusId === "2" ||
                  paramsData.disposalStatusId === "3" ||
                  paramsData.disposalStatusId === "4" ||
                  (!requester &&
                    (paramsData.disposalStatusId === "3" ||
                      paramsData.disposalStatusId === "4")) ||
                  (toolSelectOption === "notSelected" &&
                    paramsData.disposalStatusId === "")
                    ? true
                    : false
                }
                clearOnBlur
                value={row}
                aria-label={"tool-number-description-select"}
                fullWidth
                options={toolValue}
                getOptionLabel={(option: any) =>
                  option.toolNumber != null
                    ? `${option.toolNumber + " - " + option.toolDescription}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tool Number - Description "
                    variant="outlined"
                  />
                )}
                onChange={(event, options) => {
                  if (!options) {
                    return;
                  }
                  handleFilterChange(options);
                }}
                onInputChange={(event, value) => {
                  if (!value) {
                    return;
                  }
                  handleToolDebounce(value);
                }}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              fullWidth
              disabled={
                paramsData.disposalStatusId === "2" ||
                paramsData.disposalStatusId === "3" ||
                paramsData.disposalStatusId === "4" ||
                (!requester &&
                  (paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4"))
                  ? true
                  : false
              }
              value={
                row.reasonForRequest === null ||
                row.reasonForRequest === undefined
                  ? ""
                  : row.reasonForRequest
              }
              label="Summary - Reason for Request"
              variant="outlined"
              onChange={(event) => handleReasonForRequest(event.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      {(row.disposalStatusId === "" || row.disposalStatusId === "1") && (
        <Box data-tut={"reactour__approvers"}>
          <Grid item>
            {" "}
            <RequestHeader header={headerApprovers} />{" "}
          </Grid>

          <Grid
            container
            direction={"column"}
            spacing={2}
            data-tut={"reactour__filter-Module"}
          >
            <Grid item>
              <Autocomplete
                id="sourcing-manager-select"
                disabled={
                  paramsData.disposalStatusId === "2" ||
                  paramsData.disposalStatusId === "3" ||
                  paramsData.disposalStatusId === "4" ||
                  (!requester &&
                    (paramsData.disposalStatusId === "3" ||
                      paramsData.disposalStatusId === "4"))
                    ? true
                    : false
                }
                clearOnBlur
                aria-label={"sourcing-manager-select"}
                fullWidth
                value={sourcingValue}
                options={updateApprovers}
                getOptionLabel={(option: any) =>
                  option.name != null
                    ? `${option.name + "   (" + option.email + ")"}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sourcing Manager"
                    variant="outlined"
                  />
                )}
                onChange={(event, options: any) => {
                  setSourcingValue(options);
                  handleApproverManagerchange({
                    ...options,
                    role: "Sourcing Manager",
                  });
                }}
                onInputChange={(event, value) => {
                  if (!value) {
                    return;
                  }
                  handleChangeDebounce(value);
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id="engineering-manager-select"
                disabled={
                  paramsData.disposalStatusId === "2" ||
                  paramsData.disposalStatusId === "3" ||
                  paramsData.disposalStatusId === "4" ||
                  (!requester &&
                    (paramsData.disposalStatusId === "3" ||
                      paramsData.disposalStatusId === "4"))
                    ? true
                    : false
                }
                clearOnBlur
                aria-label={"engineering-manager-select"}
                fullWidth
                value={engineeringManagerValue}
                options={updateApprovers}
                getOptionLabel={(option: any) =>
                  option.name != null
                    ? `${option.name + "   (" + option.email + ")"}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Engineering Manager "
                    variant="outlined"
                  />
                )}
                onChange={(event, options: any) => {
                  setEngineeringManagerValue(options);
                  handleApproverManagerchange({
                    ...options,
                    role: "Engineering Manager",
                  });
                }}
                onInputChange={(event, value) => {
                  if (!value) {
                    return;
                  }
                  handleChangeDebounce(value);
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id="service-manager-select"
                disabled={
                  paramsData.disposalStatusId === "2" ||
                  paramsData.disposalStatusId === "3" ||
                  paramsData.disposalStatusId === "4" ||
                  (!requester &&
                    (paramsData.disposalStatusId === "3" ||
                      paramsData.disposalStatusId === "4"))
                    ? true
                    : false
                }
                clearOnBlur
                aria-label={"service-manager-select"}
                fullWidth
                value={pGApproverValue}
                options={updateApprovers}
                getOptionLabel={(option: any) =>
                  option.name != null
                    ? `${option.name + "   (" + option.email + ")"}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"PG&A Approver"}
                    variant="outlined"
                  />
                )}
                onChange={(event, options) => {
                  if (!options) {
                    return;
                  }
                  setPGApproverValue(options);
                  handleApproverManagerchange({
                    ...options,
                    role: "Service Manager",
                  });
                }}
                onInputChange={(event, value) => {
                  if (!value) {
                    return;
                  }
                  handleChangeDebounce(value);
                }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id="eol-tooling-select"
                disabled={
                  paramsData.disposalStatusId === "2" ||
                  paramsData.disposalStatusId === "3" ||
                  paramsData.disposalStatusId === "4" ||
                  (!requester &&
                    (paramsData.disposalStatusId === "3" ||
                      paramsData.disposalStatusId === "4"))
                    ? true
                    : false
                }
                clearOnBlur
                aria-label={"eol-tooling-select"}
                fullWidth
                value={eolToolingSelectValue}
                options={updateApprovers}
                getOptionLabel={(option: any) =>
                  option.name != null
                    ? `${option.name + "   (" + option.email + ")"}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="EOL Tooling Asset Manager"
                    variant="outlined"
                  />
                )}
                onChange={(event, options) => {
                  if (!options) {
                    return;
                  }
                  setEolToolingSelectValue(options);
                  handleApproverManagerchange({
                    ...options,
                    role: "EOL Tooling Asset Manager",
                  });
                }}
                onInputChange={(event, value) => {
                  if (!value) {
                    return;
                  }
                  handleChangeDebounce(value);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid item>
        {" "}
        <RequestHeader header={headerAttachments} />{" "}
      </Grid>

      <Grid
        container
        direction={"column"}
        spacing={1}
        data-tut={"reactour__filter-Module"}
      >
        <Grid item>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={(e: any) => {
              if (row && row.requestId) {
                handleFileChange(
                  e.target.files && e.target.files[0],
                  row.requestId
                );
              } else {
                handleTempFileChange(
                  e.target.files && e.target.files[0],
                  guiID
                );
              }
            }}
          />
          <Button
            data-tut={"reactour__attach"}
            variant="contained"
            color="primary"
            disabled={
              paramsData.disposalStatusId === "2" ||
              paramsData.disposalStatusId === "3" ||
              paramsData.disposalStatusId === "4" ||
              (!requester &&
                (paramsData.disposalStatusId === "3" ||
                  paramsData.disposalStatusId === "4"))
                ? true
                : false || isAttachLoading
            }
            onClick={onClickAttachments}
            startIcon={<AttachFileIcon />}
          >
            {isAttachLoading ? (
              <Box mt={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>Uploading...</Grid>
                  <Grid item>
                    <CircularProgress color={"inherit"} size={"1.5rem"} />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              "ATTACH"
            )}
          </Button>
        </Grid>
        {row &&
          row.attachments &&
          row.attachments.attachment &&
          row.attachments.attachment.map((e, index) => (
            <Box mt={1} sx={{ boxShadow: " 2px 4px  rgb(235, 241, 247)" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>{e.attachmentName}</Grid>
                <Grid item>
                  <Grid direction="row" alignItems="center" container>
                    <Box>
                      <IconButton
                        color={"primary"}
                        onClick={() => downloadAttachmentFile(e, index)}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton
                        color={
                          paramsData.disposalStatusId === "2" ||
                          paramsData.disposalStatusId === "3" ||
                          paramsData.disposalStatusId === "4"
                            ? "secondary"
                            : "primary"
                        }
                        onClick={() =>
                          paramsData.disposalStatusId === "2" ||
                          paramsData.disposalStatusId === "3" ||
                          paramsData.disposalStatusId === "4"
                            ? console.log("")
                            : deleteAttachmentFile(e, index)
                        }
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    clearFilterContainer: {
      marginTop: theme.spacing(2.5),
      display: "flex",
    },
    filterWrapper: {
      "& .MuiAutocomplete-noOptions": {
        color: theme.palette.text.primary,
      },
      margin: theme.spacing(0, 1, 0, 0),
      "& .MuiFormLabel-root": {
        color: theme.palette.secondary.main,
      },
    },
    inputField: {
      width: "6.25rem",
    },
    switch: {
      "& .MuiFormControlLabel-label": {
        lineHeight: "1",
      },
    },
    toggle: {
      margin: theme.spacing(0, 1),
    },
  })
);
