import {
  CustomPaging,
  PagingState,
  RowDetailState,
  Sorting,
  SortingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableHeaderRow,
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, Paper } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useAppData } from "./../services/hooks";
import { ToolDisposalCellComponent } from "./toolDisposalCellComponent";
import { ToolDisposalRow } from "./toolDisposalRow";
import { ToolDisposalNoDataCell } from "./toolDisposalNoDataCell";
import { ToolDisposalSortLabel } from "./toolDisposalSortLabel";
import { ToolDisposalTableComponent } from "./toolDisposalTableComponent";
import { ToolDisposalTableRow } from "./toolDisposalTableRow";
import { ToolDisposalToggleCell } from "./toolDisposalToggleCell";
import { ColumnType } from "../services/shared/baseTypes";
import ApprovedIcon from "../assets/IconApproved.svg";
import DraftIcon from "../assets/IconDraft.svg";
import InProcessIcon from "../assets/IconProcess.svg";
import RejectIcon from "../assets/IconRejected.svg";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  setToolDisposalSortAction,
} from "./toolDisposalSlice";
export type TableColumnExtensions = {
  columnName: string;
  wordWrapEnabled: boolean;
  width?: number | string;
};

export const ToolDisposalGrid: FC<any> = ({ toolDisposalData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageSizes] = useState([5, 10, 15, 0]);
  const requestorValue = useAppData(
    (r) => r.toolDisposalSlice.requestorInfo.requestorValue
  );
  const totalCount = useAppData(
    (r) => r.toolDisposalSlice.toolDisposals.value.length
  );
  const filters = useAppData((r) => r.toolDisposalSlice.filters);
  const sortByColumnName =
    filters.SortColumn === undefined
      ? ""
      : filters.SortColumn.replace(/^(.)/, (m: string) => m.toLowerCase());
  const sortDirection = filters.sortDirection === "Ascending" ? "asc" : "desc";
  var dataWithIcon = toolDisposalData.map((toolDisposalData: any) => ({
    ...toolDisposalData,
    icon:
      toolDisposalData.disposalStatusId === "1"
        ? DraftIcon
        : toolDisposalData.disposalStatusId === "2"
        ? InProcessIcon
        : toolDisposalData.disposalStatusId === "3"
        ? ApprovedIcon
        : toolDisposalData.disposalStatusId === "4"
        ? RejectIcon
        : DraftIcon,
    partNumber:
      toolDisposalData.partDetails.partDetail[0].partNumber != null
        ? toolDisposalData.partDetails.partDetail[0].partNumber
        : "-",
    requester:
      toolDisposalData.requester != null ? toolDisposalData.requester : "-",
    toolNumber:
      toolDisposalData.toolNumber + "-" + toolDisposalData.toolDescription,
    approverManagers:
      toolDisposalData.myApprovalStatus === null ||
      toolDisposalData.myApprovalStatus === ""
        ? "-"
        : toolDisposalData.myApprovalStatus,
    requestId: (
      <Link
        to={{
          pathname: `${"/disposal-request/" + toolDisposalData.requestId}`,
          state: {
            rowData: {
              ...toolDisposalData,
              lastUpdatedUser: requestorValue.displayName,
            },
          },
        }}
      >
        {toolDisposalData.requestId}
      </Link>
    ),
    lastModifiedDate:
      toolDisposalData.lastUpdatedDateTime != null
        ? moment(toolDisposalData.lastUpdatedDateTime)
            .utc()
            .format("YYYY-MM-DD")
        : "-",
    completedDateformated: toolDisposalData.completedDate
      ? moment(toolDisposalData.completedDate).utc().format("YYYY-MM-DD")
      : "-",
  }));
  dataWithIcon = dataWithIcon.map(function (data: any) {
    data.icon = (
      <div className={classes.icon}>
        <img src={data.icon} />
      </div>
    );
    return data;
  });
  const columns: ColumnType[] = [
    { name: "id", title: "ID" },
    { name: "icon", title: "status" },
    { name: "requestId", title: "request id", wordWrapEnabled: true },
    {
      name: "toolNumber",
      title: "tool number-description",
      wordWrapEnabled: true,
    },
    {
      name: "partNumber",
      title: "Part Number (*multi)",
      wordWrapEnabled: true,
    },
    { name: "requester", title: "requested by", wordWrapEnabled: true },
    {
      name: "approverManagers",
      title: "My Approval Status",
      wordWrapEnabled: true,
    },
    {
      name: "lastModifiedDate",
      title: "started / last modified date",
      wordWrapEnabled: true,
    },
    {
      name: "completedDateformated",
      title: "due / completed date",
      wordWrapEnabled: true,
    },
  ];
  const tableColumnExtensions: TableColumnExtensions[] = [
    {
      columnName: "status",
      wordWrapEnabled: true,
    },
    {
      columnName: "requestId",
      wordWrapEnabled: true,
    },
    {
      columnName: "toolNumber",
      wordWrapEnabled: true,
    },
    {
      columnName: "partNumber",
      wordWrapEnabled: true,
    },
    {
      columnName: "requester",
      wordWrapEnabled: true,
    },
    {
      columnName: "approverManagers",
      wordWrapEnabled: true,
    },
    {
      columnName: "lastUpdatedDateTime",
      wordWrapEnabled: true,
    },
    {
      columnName: "completedDate",
      wordWrapEnabled: true,
    },
  ];

  const sortingStateColumnExtensions: Array<SortingState.ColumnExtension> = [
    {
      columnName: "toolNumber",
      sortingEnabled: true,
    },
    {
      columnName: "requestId",
      sortingEnabled: true,
    },
    {
      columnName: "lastModifiedDate",
      sortingEnabled: true,
    },
   
  ];

  const handleSortOrderChange = (e: Sorting[]) => {
    if(e[0].columnName === 'requestId' || e[0].columnName === 'toolNumber' || e[0].columnName === 'lastModifiedDate'  ){
      const updatedSorting = e.map(({ columnName, direction }) => ({
        columnName: columnName,
        direction,
      }));
      dispatch(setToolDisposalSortAction(updatedSorting));
    };
   
  };
  return (
    <Paper className={classes.root}>
      <Grid rows={dataWithIcon} columns={columns}>
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <PagingPanel pageSizes={pageSizes} />
        <CustomPaging totalCount={totalCount} />
        <SortingState
          onSortingChange={(e) => handleSortOrderChange(e)}
          defaultSorting={[
            {
              columnName: sortByColumnName,
              direction: sortDirection,
            },
          ]}
          columnExtensions={sortingStateColumnExtensions}
        />
        <DragDropProvider />
        <RowDetailState />
        <Table
          tableComponent={ToolDisposalTableComponent}
          columnExtensions={tableColumnExtensions}
          noDataCellComponent={ToolDisposalNoDataCell}
          rowComponent={ToolDisposalTableRow}
          cellComponent={ToolDisposalCellComponent}
        />

        <TableHeaderRow
          showSortingControls
          sortLabelComponent={ToolDisposalSortLabel}
        />
        <TableColumnVisibility defaultHiddenColumnNames={["id"]} />
        <TableRowDetail
          contentComponent={ToolDisposalRow}
          toggleCellComponent={ToolDisposalToggleCell}
        />
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .MuiTable-root": {
        minWidth: "inherit !important",
      },
    },
    icon: {
      display: "flex",
      justifyContent: "center",
    },
    groupCell: {
      "& > div": {
        backgroundColor: "inherit",
      },
    },
  })
);
