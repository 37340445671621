import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RequestHeader } from "./newRequestHeader";
import { NewRequestGrid } from "./newRequestGrid";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import { useAppData } from "../../services/hooks";
import { AddPartModel } from "./newRequestAddPartModel";
import { ApproveCommentModel } from "./newRequestApproveCommentModel";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import {
  getNewRequest_stateAction,
  submitDataAction,
  getNewRequest_suppliersAction,
} from "../toolDisposalSlice";
import { TableRowDetail } from "@devexpress/dx-react-grid";

// var modelYearMin: any = [];
// for (var i = 2004; i < new Date().getFullYear() + 4; i++) {
//   modelYearMin.push({
//     modelYearMin: i,
//   });
// }

// To be implemented using map TODO
const modelYearMin_data = [
  { modelYearMin: "2004" },
  { modelYearMin: "2005" },
  { modelYearMin: "2006" },
  { modelYearMin: "2007" },
  { modelYearMin: "2008" },
  { modelYearMin: "2009" },
  { modelYearMin: "2010" },
  { modelYearMin: "2011" },
  { modelYearMin: "2012" },
  { modelYearMin: "2013" },
  { modelYearMin: "2014" },
  { modelYearMin: "2015" },
  { modelYearMin: "2016" },
  { modelYearMin: "2017" },
  { modelYearMin: "2018" },
  { modelYearMin: "2019" },
  { modelYearMin: "2020" },
  { modelYearMin: "2021" },
  { modelYearMin: "2022" },
  { modelYearMin: "2023" },
  { modelYearMin: "2024" },
  { modelYearMin: "2025" },
];

// To be implemented using map TODO
const modelYearMax_data = [
  { modelYearMax: "2004" },
  { modelYearMax: "2005" },
  { modelYearMax: "2006" },
  { modelYearMax: "2007" },
  { modelYearMax: "2008" },
  { modelYearMax: "2009" },
  { modelYearMax: "2010" },
  { modelYearMax: "2011" },
  { modelYearMax: "2012" },
  { modelYearMax: "2013" },
  { modelYearMax: "2014" },
  { modelYearMax: "2015" },
  { modelYearMax: "2016" },
  { modelYearMax: "2017" },
  { modelYearMax: "2018" },
  { modelYearMax: "2019" },
  { modelYearMax: "2020" },
  { modelYearMax: "2021" },
  { modelYearMax: "2022" },
  { modelYearMax: "2023" },
  { modelYearMax: "2024" },
  { modelYearMax: "2025" },
];

var modelYearMax: any = [];
for (var i = 2004; i < new Date().getFullYear() + 4; i++) {
  modelYearMax.push({
    modelYearMax: i,
  });
}

const willPolaris = [
  { willPolarisReceiveCredit: "true" },
  { willPolarisReceiveCredit: "false" },
];
const partResponsible = [
  { partyResponsibleForDisposition: "Supplier" },
  { partyResponsibleForDisposition: "Polaris" },
];
export const RightSection = ({ paramsData, requester }): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productLinese = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.productLinesValue
  );
  const toolTypesValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.toolTypesValue
  );
  const countriesValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.countriesValue
  );
  const projectNumberValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.projectNumberValue
  );
  const supplierValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.suppliersValue
  );
  const supplierNumberValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.suppliersNumberValue
  );
  const statesValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.statesValue
  );
  const itemValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.itemValue
  );
  const toolValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.toolValue
  );
  const idValue = useAppData(
    (r) => r.toolDisposalSlice.toolInformation.idValue
  );
  const row = useAppData((r) => r.toolDisposalSlice.updateValues.value);
  const header1 = "TOOL INFORMATION";
  const header = "DISPOSAL INFORMATION";

  const createTagHandler =
    (
      tagType:
        | "supplierNumber"
        | "toolTypeId"
        | "poNumber"
        | "productLines"
        | "modelYearMin"
        | "modelYearMax"
        | "countryToolResidesIn"
        | "totalPOValue"
        | "projectNumber"
        | "lastUsedDate"
        | "stateToolResidesIn"
        | "reasonForDisposition"
        | "estToolScrapValue"
        | "partyResponsibleForDisposition"
        | "willPolarisReceiveCredit"
        | "explainationForScrapAggrement"
        | "supplierName"
        | "countryToolResidesInName"
    ) =>
    (value: any) => {
      const { ...rowdata } = row;
      if (tagType === "supplierName") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              supplierName: value.supplierName,
              supplierNumber: value.supplierNumber,
            },
          })
        );
      } else if (tagType === "toolTypeId") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              toolTypeId: value.toolTypeId,
              toolType: value.toolType,
            },
          })
        );
      } else if (tagType === "countryToolResidesIn") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              countryToolResidesIn: value.countryToolResidesIn,
              countryToolResidesInName: value.countryToolResidesInName,
              stateToolResidesIn: null,
              stateToolResidesInName: null,
            },
          })
        );
      } else if (tagType === "stateToolResidesIn") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              stateToolResidesIn: value.stateToolResidesIn,
              stateToolResidesInName: value.stateToolResidesInName,
            },
          })
        );
      } else if (tagType === "countryToolResidesInName") {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              countryToolResidesInName: value,
            },
          })
        );
      } else {
        dispatch(
          submitDataAction({
            body: {
              ...rowdata,
              [tagType]: value,
            },
          })
        );
      }
    };
  const handleSupplierName = createTagHandler("supplierName");
  const handleToolTypeId = createTagHandler("toolTypeId");
  const handlePoNumber = createTagHandler("poNumber");
  const handleProductLines = createTagHandler("productLines");
  const handleModelYearMin = createTagHandler("modelYearMin");
  const handleModelYearMax = createTagHandler("modelYearMax");
  const handleCountryToolResidesIn = createTagHandler("countryToolResidesIn");
  const handleTotalPOValue = createTagHandler("totalPOValue");
  const handleProjectNumber = createTagHandler("projectNumber");
  const handlelastUsedDate = createTagHandler("lastUsedDate");
  const handleStateToolResidesIn = createTagHandler("stateToolResidesIn");
  const handleReasonForDisposition = createTagHandler("reasonForDisposition");
  const handleEstToolScrapValue = createTagHandler("estToolScrapValue");
  const handleCountryToolResidesInName = createTagHandler(
    "countryToolResidesInName"
  );

  const handlePartyResponsibleForDisposition = createTagHandler(
    "partyResponsibleForDisposition"
  );
  const handleWillPolarisReceiveCredit = createTagHandler(
    "willPolarisReceiveCredit"
  );
  const handleExplainationForScrapAggrement = createTagHandler(
    "explainationForScrapAggrement"
  );

  const lastUsedDate = row.lastUsedDate;
  function formatDate(date: any) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const lastUsedDateFormated = formatDate(lastUsedDate);

  function handleCountryChange(value: any) {
    dispatch(getNewRequest_stateAction(value));
  }

  let rowDisposal = {
    ...row,
    partyResponsibleForDisposition:
      row.partyResponsibleForDisposition === null
        ? ""
        : row.partyResponsibleForDisposition,
  };
  const updatedTool = toolTypesValue.map((object) => {
    return {
      toolType: object.description,
      toolTypeId: object.id,
    };
  });
  const productLineseUpdated = productLinese.map((object) => {
    return {
      ...object,
      productLines: object.name,
    };
  });

  const projectNumberUpdated = projectNumberValue.map((object) => {
    return {
      ...object,
      projectNumber: object.number,
    };
  });

  const countriesValueUpdated = countriesValue.map((object) => {
    return {
      ...object,
      countryToolResidesIn: object.code,
      countryToolResidesInName: object.name,
    };
  });

  const stateToolResidesInUpdated = statesValue.map((object) => {
    return {
      stateToolResidesIn: object.code,
      stateToolResidesInName: object.name,
    };
  });

  const handleSupplierDebounce = debounce((value) => {
    value.length > 2 && dispatch(getNewRequest_suppliersAction(value));
  }, 1000);

  const merge = (...arrays) => {
    const merged = {};

    arrays.forEach((data) =>
      data.forEach((o) => Object.assign((merged[o.supplierNumber] ??= {}), o))
    );

    return Object.values(merged);
  };
  let totalSupplierData = merge(supplierNumberValue, supplierValue);

  useEffect(() => {
    if (row && row.requester && countriesValue.length > 0) {
      let country = countriesValue.filter((item) => {
        return item.code === row.countryToolResidesIn;
      });

      let conName = country && country[0] && country[0].name;
      handleCountryToolResidesInName(conName);
    }
  }, [row.countryToolResidesIn, countriesValue]);

  useEffect(() => {
    handleCountryChange(row.countryToolResidesIn);
  }, [row.countryToolResidesIn]);

  useEffect(() => {
    if (
      toolTypesValue &&
      toolTypesValue.length > 0 &&
      row &&
      row.toolTypeId != undefined &&
      row.countryToolResidesInName
    ) {
      let tool = toolTypesValue.filter((item) => {
        return item.id === row.toolTypeId;
      });
      let tooObj = {
        toolType: tool[0].description,
        toolTypeId: tool[0].id,
      };
      handleToolTypeId(tooObj);
    }
  }, [toolTypesValue, row.toolTypeId, row.countryToolResidesInName]);

  const stateUseEffect = () => {
    let state = stateToolResidesInUpdated.filter((item) => {
      return item.stateToolResidesIn === row.stateToolResidesIn;
    });
    state.length > 0 && handleStateToolResidesIn(state[0]);
  };

  useEffect(() => {
    setTimeout(stateUseEffect, 1500);
  }, [stateToolResidesInUpdated.length > 0]);
  return (
    <div className={classes.filterWrapper}>
      <Box data-tut={"reactour__toolInformation"}>
        <Grid item>
          {" "}
          <RequestHeader header={header1} />{" "}
        </Grid>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <Autocomplete
                  id="supplier-number-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  filterSelectedOptions
                  value={row}
                  clearOnBlur
                  fullWidth
                  options={totalSupplierData.map((option) => option)}
                  getOptionLabel={(option: any) =>
                    option.supplierName != null
                      ? `${option.supplierNumber + " - " + option.supplierName}`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier Number / Name"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, option) => {
                    handleSupplierName(option as string);
                  }}
                  onInputChange={(event, value) => {
                    if (!value) {
                      return;
                    }
                    handleSupplierDebounce(value);
                  }}
                />
              </Grid>

              <Grid item>
                <TextField
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  fullWidth
                  value={
                    row.poNumber === null || row.poNumber === undefined
                      ? ""
                      : row.poNumber
                  }
                  label="Original PO Number"
                  variant="outlined"
                  onChange={(event) => handlePoNumber(event.target.value)}
                />
              </Grid>

              <Grid item>
                <Autocomplete
                  id="product-lines-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value={row}
                  clearOnBlur
                  aria-label={"product-lines-select"}
                  fullWidth
                  options={productLineseUpdated.map((option) => option)}
                  getOptionLabel={(option: any) => option.productLines}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Lines"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options) => {
                    handleProductLines(options.productLines);
                  }}
                />
              </Grid>

              <Grid item>
                <Grid container direction={"row"} spacing={1}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                      id="model-year-min-select"
                      value={row}
                      disabled={
                        paramsData.disposalStatusId === "2" ||
                        paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4" ||
                        (!requester &&
                          (paramsData.disposalStatusId === "3" ||
                            paramsData.disposalStatusId === "4"))
                          ? true
                          : false
                      }
                      clearOnBlur
                      aria-label={"model-year-min-select"}
                      fullWidth
                      options={modelYearMin_data}
                      getOptionLabel={(option: any) => option.modelYearMin}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Model Year - Min"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, options) => {
                        if (!options) {
                          return;
                        }
                        handleModelYearMin(options.modelYearMin as string);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                      id="model-year-max-select"
                      disabled={
                        paramsData.disposalStatusId === "2" ||
                        paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4" ||
                        (!requester &&
                          (paramsData.disposalStatusId === "3" ||
                            paramsData.disposalStatusId === "4"))
                          ? true
                          : false
                      }
                      value={row}
                      clearOnBlur
                      aria-label={"model-year-max-select"}
                      fullWidth
                      options={modelYearMax_data}
                      getOptionLabel={(option: any) => option.modelYearMax}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Model Year - Max"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, options) => {
                        if (!options) {
                          return;
                        }
                        handleModelYearMax(options.modelYearMax as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Autocomplete
                  id="country-tool-resides-in-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value={row}
                  clearOnBlur
                  aria-label={"country-tool-resides-in-select"}
                  fullWidth
                  options={countriesValueUpdated.map((option) => option)}
                  getOptionLabel={(option: any) =>
                    option.countryToolResidesIn &&
                    option.countryToolResidesInName != undefined
                      ? `${option.countryToolResidesInName} (${option.countryToolResidesIn}) `
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Tool Resides In"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options) => {
                    if (!options) {
                      return;
                    }
                    handleCountryToolResidesIn(options);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <Autocomplete
                  id="tool-type-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  clearOnBlur
                  value={row}
                  aria-label={"tool-type-select"}
                  fullWidth
                  options={updatedTool}
                  getOptionLabel={(option: any) => option.toolType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tool Type"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options: any) => {
                    if (!options) {
                      return;
                    }
                    handleToolTypeId(options);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  fullWidth
                  value={
                    row.totalPOValue === null || row.totalPOValue === undefined
                      ? ""
                      : row.totalPOValue
                  }
                  label="Total Tool Value"
                  variant="outlined"
                  onChange={(event) => handleTotalPOValue(event.target.value)}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="project-number-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value={
                    row.projectNumber === null ||
                    row.projectNumber === undefined
                      ? ""
                      : row
                  }
                  clearOnBlur
                  aria-label={"project-number-select"}
                  fullWidth
                  options={projectNumberUpdated.map((option) => option)}
                  getOptionLabel={(option: any) => option.projectNumber}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Number"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options) => {
                    if (!options) {
                      return;
                    }
                    handleProjectNumber(options.number);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="date"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  label="Last Date Used"
                  type="date"
                  value={lastUsedDateFormated}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    const date = new Date(event.target.value);
                    handlelastUsedDate(date as unknown as string);
                  }}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="state-tool-resides-in-select"
                  disabled={
                    paramsData.disposalStatusId === "2" ||
                    paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4" ||
                    (!requester &&
                      (paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4"))
                      ? true
                      : false
                  }
                  value={
                    row.stateToolResidesInName === null ||
                    row.stateToolResidesInName === undefined
                      ? ""
                      : row
                  }
                  clearOnBlur
                  aria-label={"state-tool-resides-in-select"}
                  fullWidth
                  options={stateToolResidesInUpdated.map((option) => option)}
                  getOptionLabel={(option: any) =>
                    option.stateToolResidesIn != null
                      ? `${
                          option.stateToolResidesInName +
                          " " +
                          "(" +
                          option.stateToolResidesIn +
                          ")"
                        }`
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State Tool Resides In"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, options) => {
                    if (!options) {
                      return;
                    }
                    handleStateToolResidesIn(options);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>{" "}
      </Box>

      <Grid container direction={"row"} justifyContent={"center"}>
        <Box my={3}>
          <NewRequestGrid SupplierData={totalSupplierData} />
          <Box mt={4}>
            <ApproveCommentModel />
          </Box>
        </Box>
      </Grid>

      <Box data-tut={"reactour__dispositionInformation"}>
        <Grid item>
          {" "}
          <RequestHeader header={header} />{" "}
        </Grid>
        <Grid
          container
          direction={"column"}
          spacing={2}
          data-tut={"reactour__filter-Module"}
        >
          <Grid item>
            <TextField
              fullWidth
              disabled={
                paramsData.disposalStatusId === "2" ||
                paramsData.disposalStatusId === "3" ||
                paramsData.disposalStatusId === "4" ||
                (!requester &&
                  (paramsData.disposalStatusId === "3" ||
                    paramsData.disposalStatusId === "4"))
                  ? true
                  : false
              }
              value={
                row.reasonForDisposition === null ||
                row.reasonForDisposition === undefined
                  ? ""
                  : row.reasonForDisposition
              }
              label="Description - Reason for Request"
              variant="outlined"
              onChange={(event) =>
                handleReasonForDisposition(event.target.value)
              }
            />
          </Grid>

          <Grid item>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <TextField
                      type="number"
                      disabled={
                        paramsData.disposalStatusId === "2" ||
                        paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4" ||
                        (!requester &&
                          (paramsData.disposalStatusId === "3" ||
                            paramsData.disposalStatusId === "4"))
                          ? true
                          : false
                      }
                      fullWidth
                      value={
                        row.estToolScrapValue === null ||
                        row.estToolScrapValue === undefined
                          ? ""
                          : row.estToolScrapValue
                      }
                      label="Estimated Tool Scrap Value"
                      variant="outlined"
                      onChange={(event) =>
                        handleEstToolScrapValue(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      id="party-responsible-for-disposition-select"
                      disabled={
                        paramsData.disposalStatusId === "2" ||
                        paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4" ||
                        (!requester &&
                          (paramsData.disposalStatusId === "3" ||
                            paramsData.disposalStatusId === "4"))
                          ? true
                          : false
                      }
                      clearOnBlur
                      value={rowDisposal}
                      aria-label={"party-responsible-for-disposition-select"}
                      fullWidth
                      options={partResponsible}
                      getOptionLabel={(option) =>
                        option.partyResponsibleForDisposition
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Party Responsible for Disposal"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, options) => {
                        if (!options) {
                          return;
                        }
                        handlePartyResponsibleForDisposition(
                          options.partyResponsibleForDisposition as string
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <Autocomplete
                      id="part-number-select"
                      disabled={
                        paramsData.disposalStatusId === "2" ||
                        paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4" ||
                        (!requester &&
                          (paramsData.disposalStatusId === "3" ||
                            paramsData.disposalStatusId === "4"))
                          ? true
                          : false
                      }
                      clearOnBlur
                      aria-label={"part-number-select"}
                      fullWidth
                      value={row}
                      options={willPolaris}
                      getOptionLabel={(option) =>
                        option.willPolarisReceiveCredit === "true"
                          ? "Yes"
                          : option.willPolarisReceiveCredit === "false"
                          ? "No"
                          : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Will Polaris Receive a Credit/Check for Disposal?"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, options) => {
                        if (!options) {
                          return;
                        }
                        handleWillPolarisReceiveCredit(
                          options.willPolarisReceiveCredit as string
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={
                        paramsData.disposalStatusId === "2" ||
                        paramsData.disposalStatusId === "3" ||
                        paramsData.disposalStatusId === "4" ||
                        (!requester &&
                          (paramsData.disposalStatusId === "3" ||
                            paramsData.disposalStatusId === "4"))
                          ? true
                          : false
                      }
                      fullWidth
                      value={
                        row.explainationForScrapAggrement === null ||
                        row.explainationForScrapAggrement === undefined
                          ? ""
                          : row.explainationForScrapAggrement
                      }
                      label="Explanation of Scrap Agreement"
                      variant="outlined"
                      onChange={(event) =>
                        handleExplainationForScrapAggrement(event.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>{" "}
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    clearFilterContainer: {
      marginTop: theme.spacing(2.5),
      display: "flex",
    },
    filterWrapper: {
      "& .MuiAutocomplete-noOptions": {
        color: theme.palette.text.primary,
      },
      margin: theme.spacing(0, 1, 0, 0),
      "& .MuiFormLabel-root": {
        color: theme.palette.secondary.main,
      },
    },
    inputField: {
      width: "6.25rem",
    },
    switch: {
      "& .MuiFormControlLabel-label": {
        lineHeight: "1",
      },
    },
    toggle: {
      margin: theme.spacing(0, 1),
    },
  })
);
