import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Button,
  Theme,
  Box,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

export const ConditionModel = ({
  open,
  handleClose,
  onClick,
  checkStatus,
  handleConfirm,
}) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card className={classes.root}>
              <CardContent>
                <Box mb={2}>
                  <Typography variant="h5" component="h2">
                    Condition for Submission
                  </Typography>
                </Box>
                <Typography variant="body2" component="p">
                  Consideration of this request will be granted only under one
                  or more of the following condition for the referenced part
                  number:
                </Typography>
                <li>Part has no known future demand</li>
                
                <li>Part have been superseded</li>
                <li>Lifetime buy for parts has been completed</li>
                <li>Parts are not serviceable</li>
                <Grid container direction="row" wrap="nowrap">
                  <Grid className={classes.check}>
                    <Checkbox
                      checked={checkStatus}
                      onChange={onClick}
                      name="checkedB"
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" component="p">
                      By checking this box, you attest that one or more of the
                      above conditions has been met, or that you have a timeline
                      for doing so that has been communicated and is amenable to
                      all approving parties
                    </Typography>
                  </Grid>
                </Grid>
                <Box my={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/* <Link href={"/tool-disposal"}> */}
                    <Button
                      className={classes.button}
                      data-tut={"reactour__download-Button"}
                      variant="contained"
                      color="primary"
                      disabled={checkStatus === true ? false : true}
                      onClick={handleConfirm}
                    >
                      CONFIRM
                    </Button>
                    {/* </Link> */}
                    <Button
                      className={classes.button}
                      data-tut={"reactour__download-Button"}
                      variant="outlined"
                      color="primary"
                      disabled={false}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    check: {
      "& .MuiButtonBase-root": {
        color: "#004e97 !important",
      },
      "& .PrivateSwitchBase-root-111": {
        padding: "0px 9px 0px 0px",
      },
    },

    root: {
      maxWidth: 420,
      padding: 12,
    },
    title: {
      fontSize: 14,
    },

    button: {
      minWidth: "7.188rem",
    },
  })
);
