import { Button, Container, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from "react-router-dom";

export const LoginPage: React.FC = () => {
  const classes = useStyles();
    const history = useHistory();

  return (
    <Container maxWidth={'xs'}>
      <Paper className={classes.paper}>
        <Grid container direction={'column'} spacing={4}>
          <Grid item>
            <Typography variant={'h4'} component={'h1'} className={classes.heading}>
              Please login
            </Typography>
          </Grid>
          <Grid item>
            <Button
              aria-label={'ToolDisposal Login'}
              variant={'contained'}
              color={'primary'}
              fullWidth
              href={`${window.location.origin + "/AzureAdAuth/Login"}`}
             
            >
                 Tool Disposal Login
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      textAlign: 'center',
    },
    paper: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(5),
    },
  }),
);
