import React, { FC } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export const ToolDisposalRow: FC<{ row: any }> = ({ row }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      direction={"row"}
      spacing={4}
      justifyContent={"center"}
      className={classes.rowContainer}
    >
      <Grid container spacing={isSmall ? 0 : 4}>
        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  Summary - Reason for Request:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightRegular" m={1}>
                  {row.reasonForRequest}
                </Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  Supplier Number:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightRegular" m={1}>
                  {row.supplierNumber}
                </Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  Supplier Name:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightRegular" m={1}>
                  {row.supplierName}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  Business Unit::
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightRegular" m={1}>
                  {row.businessUnit}
                </Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  Product Lines:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightRegular" m={1}>
                  {row.productLines}
                </Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap">
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  Model Year Min - Max:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography noWrap>
                <Box fontWeight="fontWeightRegular" m={1}>
                  {row.modelYearMin} - {row.modelYearMax}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "red",
      padding: 100,
    },

    rowContainer: {
      padding: theme.spacing(0.5, 5),
      [theme.breakpoints.up("sm")]: {},
    },
  })
);
